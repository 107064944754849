import React, { useContext, useEffect, useState } from 'react';
import { formatPrice } from '../../../hooks/contractHelper';
import Context from "./context/Context";
import { useWeb3React } from "@web3-react/core";
import { useCommonStats } from './hooks/useStats';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Api_Baseurl } from '../../../hooks/constant';
import { formatUnits } from 'ethers/lib/utils';
import { supportNetwork } from '../../../hooks/network';


export default function Step4() {
    const { value, btnNextStep, btnPrevStep, setValue } = useContext(Context);
    const context = useWeb3React();
    const {  chainId } = context;
    const [updater] = useState(new Date());
    const commonStats = useCommonStats(updater)
    const [partnerList, setPartnerList] = useState([]);
   


    const btnNextStepValidation = () => {
        btnNextStep();
    }

    useEffect(() => {
        async function fetchActivePartner() {
            try {
                let response = await axios.post(`${Api_Baseurl}api/admin/get-active-partner`,{
                    chainId
                });
                if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
                    let getData = response.data.data;
                    setPartnerList(getData);
                }
                else {
                    toast.error(response.data.error);
                }
            }
            catch (err) {
                  toast.error(err.reason ? err.reason : err.message);
            }
        }
        fetchActivePartner()
    }, [chainId])

    const handleCheckbox = (e) => {
        console.log(e.target.value);
        if (e.target.checked === true) {
            setValue({ ...value, partnerAddress: [...value.partnerAddress, e.target.name] , totalCost : parseFloat(value.totalCost) + parseFloat(e.target.value) });
        }
        else {
            const index = value.partnerAddress.indexOf(e.target.name);
            if (index > -1) { // only splice array when item is found
                value.partnerAddress.splice(index, 1); // 2nd parameter means remove one item only
            }
            value.partnerAddress.splice(index, 1);
            setValue({ ...value, partnerAddress: [...value.partnerAddress] , totalCost : parseFloat(value.totalCost) - parseFloat(e.target.value) });
        }
    }

    const handleCheckboxAudit = (e) => {
        setValue({...value , [e.target.name] : !value.audit , totalCost : e.target.checked === true ?   Math.round((parseFloat(value.totalCost) + parseFloat(commonStats.auditPrice)) * 1e12) / 1e12 : 
            Math.round((parseFloat(value.totalCost) - parseFloat(commonStats.auditPrice)) * 1e12 ) / 1e12})
        
    }

    const handleCheckboxKyc = (e) => {
        setValue({...value , [e.target.name] : !value.kyc , totalCost : e.target.checked === true ? 
            Math.round((parseFloat(value.totalCost) + parseFloat(commonStats.kycPrice)) * 1e12) / 1e12 : 
            Math.round((parseFloat(value.totalCost) - parseFloat(commonStats.kycPrice)) * 1e12 ) / 1e12})
    }

    return (
        <div className={`tab-pane ${value.step === 4 ? 'active' : ''}`} role="tabpanel" id="step4">
            <h4 className="text-center">Choose your marketing, KYC and auditing option below</h4>
            <div className="container">
                <h5 className='text-center'>Audit & Kyc Service</h5>
                <div className="row">
                    <div className="col text-center">
                        <label htmlFor="html">AUDIT</label><br />
                        <img src="../assets/images/secure.png" alt="secure" className='mb-3' height="50px" width="50px" /><br />
                        <span>{formatPrice(commonStats.auditPrice ? commonStats.auditPrice : 0)}</span><br />
                        <div className="container">
                            <label className="switch" htmlFor="audit">
                                <input type="checkbox" name="audit"  id="audit" onChange={(e) => handleCheckboxAudit(e)}  checked={value.audit === true ? true : false}  />
                                <div className="slider round"></div>
                            </label>
                        </div>
                    </div>
                    <div className="col text-center">
                        <label htmlFor="html">KYC</label><br />
                        <img src="../assets/images/kyc.png" alt="kyc" className='mb-3' height="50px" width="50px" /><br />
                        <span>{formatPrice(commonStats.kycPrice ? commonStats.kycPrice : 0)}</span><br />
                        <div className="container">
                            <label className="switch" htmlFor="kyc">
                                <input type="checkbox"  name="kyc" id="kyc" onChange={(e) => handleCheckboxKyc(e)} checked={value.kyc === true ? true : false}  />
                                <div className="slider round"></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="container">
                <h6 className='text-center'>Pre-Book Marketing For Your Project</h6>
                <h6 className='text-center'>Show Your Community You Have Marketing Booked and Pre Pay</h6>
                <div className="row">
                    {partnerList && partnerList.map((rowdata, index) => {
                        return (
                            <div className="col-md-4 text-center mt-5" key={index}>
                                <div className="wrapper">
                                    <img src={rowdata.profileUrl ? rowdata.profileUrl : '../assets/images/animition_avater.png'} alt="img-profileama" className="image--cover" />
                                </div>
                                <label htmlFor="html">{rowdata.name ? rowdata.name : 'BlockStart Partner'}</label><br />
                                <span style={{ "fontSize": "13px" }}>{rowdata.details ? `${rowdata.details}${rowdata.kyc === '1' ? ' + KYC' : ''}${rowdata.audit === '1' ? ' + AUDIT' : ''}` : 'AMA'}</span><br />
                                <span>{rowdata.fees ? formatUnits(rowdata.fees, 'ether') : '0'} {supportNetwork[chainId] ? supportNetwork[chainId].symbol : supportNetwork['default'].symbol}</span>
                                <div className="container mt-2">
                                    <label className="switch" htmlFor={rowdata.address ? rowdata.address : 'none'}>
                                        <input type="checkbox" name={rowdata.address ? rowdata.address : 'none'} value={formatUnits(rowdata.fees, 'ether')} id={rowdata.address ? rowdata.address : 'none'}
                                            onChange={(e) => handleCheckbox(e)} />
                                        <div className="slider round"></div>
                                    </label>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>

            <div className='text-center mt-2'>Total Cost : {formatPrice(value.totalCost ?  value.totalCost : 0)} {supportNetwork[chainId] ? supportNetwork[chainId].symbol : supportNetwork['default'].symbol} </div>
            <ul className="list-inline text-center">
                <li><button type="button" className="default-btn prev-step mr-4" onClick={(e) => btnPrevStep(e)}>Back</button></li>
                <li><button type="button" className="default-btn next-step" onClick={(e) => btnNextStepValidation(e)}>Continue</button></li>
            </ul>
        </div>
    )
}
