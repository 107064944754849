import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import presalePoolAbi from '../../../../json/presalePool.json';
import poolManagerAbi from '../../../../json/poolManager.json';
import lockAbi from '../../../../json/lockabi.json';
import { MulticallContractWeb3, multiCallContractConnect } from "../../../../hooks/useContracts";
import { getWeb3 } from "../../../../hooks/connectors";
import tokenAbi from '../../../../json/token.json';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { contract } from "../../../../hooks/constant";
import { supportNetwork } from "../../../../hooks/network";
import axios from "axios";
import { Api_Baseurl } from "../../../../hooks/constant";



export const useCommonStats = (update) => {
  const context = useWeb3React();
  const { chainId } = context;
  let history = useHistory();

  // let poolAddress = "0x6816e27bca20fbbe779ca9725d48c1e01a02943c";

  const location = useLocation();
  let urlAddress = location.pathname.split('/').pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get('chainid');

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);

  let poolContract = new web3.eth.Contract(presalePoolAbi, urlAddress);

  const [stats, setStats] = useState({
    endTime: 0,
    startTime: 0,
    hardCap: 0,
    softCap: 0,
    liquidityListingRate: 0,
    liquidityLockDays: 0,
    liquidityPercent: 0,
    liquidityUnlockTime: 0,
    maxContribution: 0,
    poolDetails: 0,
    poolState: 0,
    rate: 0,
    remainingContribution: 0,
    tgeLockDuration: 0,
    tgeTime: 0,
    tgeTokenReleasePer: 0,
    tgeTokensRelease: 0,
    token: 0,
    tokensReleaseEachCycle: 0,
    totalClaimed: 0,
    totalRaised: 0,
    totalVestingTokens: 0,
    useWhitelisting: 0,
    minContribution: 0,
    tokenName: '',
    tokenDecimal: 0,
    tokenSymbol: 0,
    tokenSupply: 0,
    percentageRaise: 0,
    refundType: 0,
    eachvestingPer: 0,
    cycle: 0,
    poolAddress: 0,
    governance: 0,
    kyc: false,
    audit: false,
    auditStatus: false,
    kycStatus: false,
    partnerList: [],
    totalPresaleToken: 0,
    currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol,
    insurcePrice: 0,
    kycLink: '#',
    auditLink: '#'
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
  let poolManagerAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].poolmanager : contract['default'].poolmanager;
  let pmc = new web3.eth.Contract(poolManagerAbi, poolManagerAddress);
  let lockAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].lockAddress : contract['default'].lockAddress;
  let lmc = new web3.eth.Contract(lockAbi, lockAddress);

  useEffect(() => {
    const fetch = async () => {
      try {

        const data = await mc.aggregate([
          poolContract.methods.endTime(),
          poolContract.methods.startTime(),
          poolContract.methods.hardCap(),
          poolContract.methods.softCap(),
          poolContract.methods.liquidityListingRate(),
          poolContract.methods.liquidityLockDays(),
          poolContract.methods.liquidityPercent(),
          poolContract.methods.liquidityUnlockTime(),
          poolContract.methods.maxContribution(),
          poolContract.methods.poolDetails(),
          poolContract.methods.poolState(),
          poolContract.methods.rate(),
          poolContract.methods.remainingContribution(),
          poolContract.methods.tgeLockDuration(),
          poolContract.methods.tgeTime(),
          poolContract.methods.tgeTokenReleasePer(),
          poolContract.methods.tgeTokensRelease(),
          poolContract.methods.token(),
          poolContract.methods.tokensReleaseEachCycle(),
          poolContract.methods.totalClaimed(),
          poolContract.methods.totalRaised(),
          poolContract.methods.totalVestingTokens(),
          poolContract.methods.useWhitelisting(),
          poolContract.methods.minContribution(),
          poolContract.methods.refundType(),
          poolContract.methods.eachvestingPer(),
          poolContract.methods.cycle(),
          poolContract.methods.governance(),
          poolContract.methods.kyc(),
          poolContract.methods.audit(),
          poolContract.methods.auditStatus(),
          poolContract.methods.kycStatus(),
          pmc.methods.poolPartnerList(urlAddress),
        ]);



        let tokenContract = new web3.eth.Contract(tokenAbi, data[17]);

        const tokendata = await mc.aggregate([
          tokenContract.methods.name(),
          tokenContract.methods.decimals(),
          tokenContract.methods.symbol(),
          tokenContract.methods.totalSupply(),
          tokenContract.methods.balanceOf('0x000000000000000000000000000000000000dead'),
        ]);

        const lockdata = await mc.aggregate([
          lmc.methods.cumulativeLockInfo(data[17]),
          pmc.methods.getInsurancePrice()
        ]);

        let partnerData = await axios.get(`${Api_Baseurl}api/admin/get-kyc-link`, {
          params: {
            poolAddress: urlAddress
          }
        });

        setStats({
          endTime: data[0],
          startTime: data[1],
          hardCap: data[2] / Math.pow(10, 18),
          softCap: data[3] / Math.pow(10, 18),
          liquidityListingRate: data[4] / Math.pow(10, tokendata[1]),
          liquidityLockDays: data[5],
          liquidityPercent: data[6],
          liquidityUnlockTime: data[7],
          maxContribution: data[8] / Math.pow(10, 18),
          poolDetails: data[9],
          poolState: data[10],
          rate: data[11] / Math.pow(10, tokendata[1]),
          remainingContribution: data[12] / Math.pow(10, 18),
          tgeLockDuration: data[13],
          tgeTime: data[14],
          tgeTokenReleasePer: data[15],
          tgeTokensRelease: data[16] / Math.pow(10, tokendata[1]),
          token: data[17],
          tokensReleaseEachCycle: data[18],
          totalClaimed: data[19],
          totalRaised: data[20] / Math.pow(10, 18),
          totalVestingTokens: data[21] / Math.pow(10, tokendata[1]),
          useWhitelisting: data[22],
          minContribution: data[23] / Math.pow(10, 18),
          tokenName: tokendata[0],
          tokenDecimal: tokendata[1],
          tokenSymbol: tokendata[2],
          percentageRaise: ((data[20] / Math.pow(10, 18)) / (data[2] / Math.pow(10, 18))) * 100,
          tokenSupply: tokendata[3] / Math.pow(10, tokendata[1]),
          refundType: data[24],
          eachvestingPer: data[25],
          cycle: data[26],
          poolAddress: urlAddress,
          governance: data[27],
          kyc: data[28],
          audit: data[29],
          auditStatus: data[30],
          kycStatus: data[31],
          partnerList: data[32],
          totalPresaleToken: parseFloat((data[11] / Math.pow(10, tokendata[1])) * (data[2] / Math.pow(10, 18))) + parseFloat(((data[2] / Math.pow(10, 18)) * (data[4] / Math.pow(10, tokendata[1])) * data[6]) / 100),
          currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol,
          PresalePer: (((data[2] / Math.pow(10, 18)) * (data[11] / Math.pow(10, tokendata[1]))) / (tokendata[3] / Math.pow(10, tokendata[1]))) * 100,
          LiquidityPer: (((data[2] / Math.pow(10, 18)) * (data[4] / Math.pow(10, tokendata[1]))) / (tokendata[3] / Math.pow(10, tokendata[1]))) * 100,
          BurntPer: ((tokendata[4] / Math.pow(10, tokendata[1])) / (tokendata[3] / Math.pow(10, tokendata[1]))) * 100,
          LockPer: lockdata[0] ? ((lockdata[0][2] / Math.pow(10, tokendata[1])) / (tokendata[3] / Math.pow(10, tokendata[1]))) * 100 : 0,
          insurcePrice: lockdata[1] / Math.pow(10, 18),
          kycLink: typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK' ? partnerData.data.data.kycLink : '#sec',
          auditLink: typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK' ? partnerData.data.data.auditLink : '#sec',
        });
      }
      catch (err) {
        toast.error('wrong network selected !');
        history.push('/sale-list');
      }
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        endTime: 0,
        startTime: 0,
        hardCap: 0,
        softCap: 0,
        liquidityListingRate: 0,
        liquidityLockDays: 0,
        liquidityPercent: 0,
        liquidityUnlockTime: 0,
        maxContribution: 0,
        poolDetails: 0,
        poolState: 0,
        rate: 0,
        remainingContribution: 0,
        tgeLockDuration: 0,
        tgeTime: 0,
        tgeTokenReleasePer: 0,
        tgeTokensRelease: 0,
        token: 0,
        tokensReleaseEachCycle: 0,
        totalClaimed: 0,
        totalRaised: 0,
        totalVestingTokens: 0,
        useWhitelisting: 0,
        minContribution: 0,
        tokenName: '',
        tokenDecimal: 0,
        tokenSymbol: 0,
        percentageRaise: 0,
        tokenSupply: 0,
        refundType: 0,
        eachvestingPer: 0,
        cycle: 0,
        poolAddress: 0,
        governance: 0,
        kyc: false,
        audit: false,
        auditStatus: false,
        kycStatus: false,
        partnerList: [],
        totalPresaleToken: 0,
        insurcePrice: 0,
        kycLink: '#',
        auditLink: '#',
        currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol
      })
    }
    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
}

export const useAccountStats = (updater) => {
  const context = useWeb3React();
  const { chainId, account } = context;
  const location = useLocation();
  let history = useHistory();
  let urlAddress = location.pathname.split('/').pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get('chainid');

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);

  try {
    let poolAddress = web3.utils.isAddress(urlAddress);
    let isCode = web3.eth.getCode(urlAddress);
    if (isCode === '0x' || !poolAddress) {
      history.push('/project-details');
    }
  }
  catch (err) {
    history.push('/');
  }



  let poolContract = new web3.eth.Contract(presalePoolAbi, urlAddress);

  const [stats, setStats] = useState({
    balance: '',
    contributionOf: '',
    userAvalibleClaim: '',
    haveInsurance: false
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
  const mcc = multiCallContractConnect(queryChainId ? queryChainId : chainId);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          mcc.methods.getEthBalance(account),
          poolContract.methods.contributionOf(account),
          poolContract.methods.userAvalibleClaim(account),
          poolContract.methods.isInsurance(account)

        ]);

        setStats({
          balance: data[0] / Math.pow(10, 18),
          contributionOf: data[1] / Math.pow(10, 18),
          userAvalibleClaim: data[2] / Math.pow(10, 18),
          haveInsurance: data[3]
        })
      }
      catch (err) {
        toast.error(err.reason)
        history.push('/sale-list');
      }
    }

    if (mc && account) {
      fetch();
    }
    else {
      setStats({
        balance: '',
        contributionOf: '',
        userAvalibleClaim: '',
        haveInsurance: false
      })
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
}


export const usePoolListStats = (updater) => {
  let { page, pageSize, loading } = updater;
  const context = useWeb3React();
  const { chainId, account } = context;
  let history = useHistory();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get('chainid');

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);
  let poolManagerAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].poolmanager : contract['default'].poolmanager;
  let poolManagerContract = new web3.eth.Contract(poolManagerAbi, poolManagerAddress);


  const [stats, setStats] = useState({
    getTotalNumberOfPools: 0,
    page: page,
    pageSize: pageSize,
    poolList: [],
    loading: true,
    chainId: 56,
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);


  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          poolManagerContract.methods.getTotalNumberOfPools()
        ]);


        if (data[0] > 0) {
          let start = (data[0] - 1) - (page * pageSize) - (pageSize - 1)
          let end = start + pageSize - 1;

          let poolData = [];
          if (queryChainId === 56 || queryChainId === '56' || chainId === '56' || chainId === 56) {
            poolData = await mc.aggregate([
              poolManagerContract.methods.getCumulativePoolInfo(start >= 2 ? start : 2, end < data[0] ? end : data[0])
            ]);
          }
          else {

            poolData = await mc.aggregate([
              poolManagerContract.methods.getCumulativePoolInfo(start > 0 ? start : 0, end < data[0] ? end : data[0])
            ]);
          }


          Promise.all(poolData[0].map(async (value, index) => {
            return {
              decimals: value.decimals,
              name: value.name,
              symbol: value.symbol,
              poolAddress: value.poolAddress,
              endTime: value.endTime,
              hardCap: value.hardCap / Math.pow(10, 18),
              liquidityListingRate: value.liquidityListingRate / Math.pow(10, value.decimals),
              liquidityPercent: value.liquidityPercent,
              maxContribution: value.maxContribution / Math.pow(10, 18),
              minContribution: value.minContribution / Math.pow(10, 18),
              poolState: value.poolState,
              poolType: value.poolType,
              rate: value.rate / Math.pow(10, value.decimals),
              softCap: value.softCap / Math.pow(10, 18),
              startTime: value.startTime,
              token: value.token,
              totalRaised: value.totalRaised / Math.pow(10, 18),
              percentageRaise: ((value.totalRaised / Math.pow(10, 18)) / (value.poolType === '2' ? value.softCap / Math.pow(10, 18) : value.hardCap / Math.pow(10, 18))) * 100,
              logourl: value.poolDetails.toString().split('$#$')[0]
            }
          })).then((result) => {
            setStats({
              getTotalNumberOfPools: data[0] - 2,
              poolList: result,
              page: page,
              pageSize: pageSize,
              loading: !loading,
              chainId: queryChainId ? queryChainId : chainId
            })
          })
        }
        else {
          setStats({
            getTotalNumberOfPools: 0,
            page: page,
            pageSize: pageSize,
            poolList: [],
            loading: false,
            chainId: 56
          })
        }
      }
      catch (err) {
        toast.error(err.reason)
        history.push('/');
      }
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        poolList: [],
        loading: false,
        chainId: 97
      })
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
}


export const usePoolListUser = (updater) => {
  let { page, pageSize, loading } = updater;
  const context = useWeb3React();
  const { chainId, account } = context;
  let history = useHistory();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get('chainid');

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);
  let poolManagerAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].poolmanager : contract['default'].poolmanager;
  let poolManagerContract = new web3.eth.Contract(poolManagerAbi, poolManagerAddress);


  const [stats, setStats] = useState({
    getTotalNumberOfPools: 0,
    page: page,
    pageSize: pageSize,
    poolList: [],
    loading: true,
    chainId: 56,
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);


  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          poolManagerContract.methods.getTotalNumberOfContributedPools(account)
        ]);


        if (data[0] > 0) {
          let start = (data[0] - 1) - (page * pageSize) - (pageSize - 1)
          let end = start + pageSize - 1;


          const poolData = await mc.aggregate([
            poolManagerContract.methods.getUserContributedPoolInfo(account, start >= 0 ? start : 0, end < data[0] ? end : data[0])
          ]);


          Promise.all(poolData[0].map(async (value, index) => {
            return {
              decimals: value.decimals,
              name: value.name,
              symbol: value.symbol,
              poolAddress: value.poolAddress,
              endTime: value.endTime,
              hardCap: value.hardCap / Math.pow(10, 18),
              liquidityListingRate: value.liquidityListingRate / Math.pow(10, value.decimals),
              liquidityPercent: value.liquidityPercent,
              maxContribution: value.maxContribution / Math.pow(10, 18),
              minContribution: value.minContribution / Math.pow(10, 18),
              poolState: value.poolState,
              poolType: value.poolType,
              rate: value.rate / Math.pow(10, value.decimals),
              softCap: value.softCap / Math.pow(10, 18),
              startTime: value.startTime,
              token: value.token,
              totalRaised: value.totalRaised / Math.pow(10, 18),
              percentageRaise: ((value.totalRaised / Math.pow(10, 18)) / (value.poolType === '2' ? value.softCap / Math.pow(10, 18) : value.hardCap / Math.pow(10, 18))) * 100,

            }
          })).then((result) => {
            setStats({
              getTotalNumberOfPools: data[0] - 1,
              poolList: result,
              page: page,
              pageSize: pageSize,
              loading: !loading,
              chainId: queryChainId ? queryChainId : chainId
            })
          })
        }
        else {
          setStats({
            getTotalNumberOfPools: 0,
            page: page,
            pageSize: pageSize,
            poolList: [],
            loading: false,
            chainId: 56
          })
        }
      }
      catch (err) {
        toast.error(err.reason)
        history.push('/');
      }
    }

    if (mc && account && chainId) {
      fetch();
    }
    else {
      setStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        poolList: [],
        loading: false,
        chainId: 97
      })
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
}


