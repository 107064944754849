import React from 'react'
import { trimAddress } from '../../hooks/constant';
import { formatPrice } from '../../hooks/contractHelper';
import { useDetailsStats } from './helper/useStats';
import { useWeb3React } from "@web3-react/core";
import { Link , useLocation } from 'react-router-dom';

export default function LockView() {
    const stats = useDetailsStats(1);
    const context = useWeb3React();
    const { chainId } = context;
    const search = useLocation().search;
    const queryChainId = new URLSearchParams(search).get('chainid');

    return (
        <React.Fragment>
            <div class="gamfi-breadcrumbs-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="breadcrumbs-area sec-heading">
                                <div class="sub-inner mb-15">
                                    <span class="sub-title">Lock</span>
                                    <img class="heading-left-image" src="../assets/images/icons/steps.png" alt="Steps" />
                                </div>
                                <h2 class="title mb-0">Lock info</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="igo-rankging-table-list pb-140 md-pb-60">
                <div class="container">
                    <div className='row justify-content-center'>
                        <div className='col-md-10 col-12'>
                            <div class="card bg-dark m-3">
                                <div class="card-header">
                                    <h4 class="card-title">Lock info</h4>
                                </div>

                                <div class="card-body">
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Current Locked Amount</span>
                                        <span className="step-input-value">{formatPrice(stats.cumulativeLockInfo)} {stats.TokenSymbol}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Token Address	</span>
                                        <span className="step-input-value">{stats.TokenAddress}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Token Name</span>
                                        <span className="step-input-value">{stats.TokenName}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Token Symbol</span>
                                        <span className="step-input-value">{stats.TokenSymbol}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Token Decimals</span>
                                        <span className="step-input-value">{stats.TokenDecimals}</span>
                                    </div>
                                </div>

                            </div>
                            <div class="card bg-dark m-3">
                                <div class="card-header">
                                    <h4 class="card-title">Lock records</h4>
                                </div>

                                <div class="card-body">
                                    <table class="table" cellspacing="0" cellpadding="0" style={{ "border": "none" }}>
                                        <thead>
                                            <tr>
                                                <td>Wallet</td>
                                                <td>Amount</td>
                                                <td>Cycle(d)</td>
                                                <td>Cycle Release(%)</td>
                                                <td>TGE(%)</td>
                                                <td>Unlock time(UTC)</td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {stats.lockdata.map((rowdata, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{trimAddress(rowdata.owner)}</td>
                                                        <td>{rowdata.amount / Math.pow(10, stats.TokenDecimals)}</td>
                                                        <td>{rowdata.cycle > 0 ? rowdata.cycle / 60 : '-'}</td>
                                                        <td>{rowdata.cycleBps > 0 ? rowdata.cycleBps / 100 : '-'}</td>
                                                        <td>{rowdata.tgeBps > 0 ? rowdata.tgeBps / 100 : '-'}</td>
                                                        <td style={{"fontSize" : "12px"}}>{rowdata.tgeDate ? new Date(rowdata.tgeDate * 1000).toUTCString().substring(4, 25) : '-'}</td>
                                                        <td><Link style={{ "color": "#47c8f2" }} to={`/lock-record/${rowdata.id}${queryChainId ? `?chainid=${queryChainId}` : chainId ? `?chainid=${chainId}` : ''}`}>View</Link></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
