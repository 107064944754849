import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Api_Baseurl } from '../hooks/constant';
import axios from 'axios';
import { toast } from 'react-toastify';
import { supportNetwork } from '../hooks/network';
import HashLoader from "react-spinners/HashLoader";
import { formatPrice } from '../hooks/contractHelper';


export default function Home() {
    const [dataload, setDataload] = useState(true);
    const [openSale, setOpenSale] = useState([]);
    const [currentTime] = useState(Math.floor(new Date().getTime() / 1000.0));

    
    useEffect(() => {
        async function fetchData() {
            axios.get(`${Api_Baseurl}api/pooldata/getFeaturedPools`)
                .then(function (response) {
                    if (response) {
                        let data = response.data;
                        if (data.error === 'OK') {
                            console.log(data.data);
                            setOpenSale(data.data);
                            setDataload(false);
                        }
                        else {
                            toast.error('Something went wrong ! try again')

                        }
                    }
                })
                .catch(function (error) {
                    toast.error('Something went wrong ! try again')
                })
        }

        fetchData();
        // eslint-disable-next-line
    }, []);


    return (
        <React.Fragment>
            {/* Banner Section Start  */}
            <div id="sc-banner" className="sc-banner banner-bg position-relative">
                <div className="container">
                    <div className="banner-content text-center">
                        <h1 className="banner-title wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="0.7s">
                            BlockStar Web 3.0 Launchpad
                        </h1>
                        <Link to="/sale-list" className="banner-btn wow fadeInUp black-shape-big mr-5" data-wow-delay="0.4s" data-wow-duration="0.7s" >
                            <span className="btn-text">Explore</span>
                            <span className="hover-shape1"></span>
                            <span className="hover-shape2"></span>
                            <span className="hover-shape3"></span>
                        </Link>
                        <div class="dropdown" style={{ "display": "inline-block" }}>
                            <a href='#sec' class="banner-btn wow fadeInUp black-shape-big ml-5 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="btn-text">Launch Project</span>
                                <span className="hover-shape1"></span>
                                <span className="hover-shape2"></span>
                                <span className="hover-shape3"></span>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li style={{ "borderBottom": "1px solid #000" }}><Link to="/presale" class="dropdown-item" >Presale</Link></li>
                                <li style={{ "borderBottom": "1px solid #000" }}><Link to="/privatesale" class="dropdown-item" >Private Sale</Link></li>
                                <li><Link to="/fairsale" class="dropdown-item" >Fair Launch</Link></li>
                            </ul>
                        </div>
                        {/* <Link to="/presale" className="banner-btn wow fadeInUp black-shape-big ml-5" data-wow-delay="0.4s" data-wow-duration="0.7s" >
                            <span className="btn-text">Launch Project</span>
                            <span className="hover-shape1"></span>
                            <span className="hover-shape2"></span>
                            <span className="hover-shape3"></span>
                        </Link> */}
                    </div>
                </div>
            </div>
            {/* Banner Section End  */}

            <div class="gamfi-previous-section pb-90 md-pb-50">
                <div class="container">
                    <div class="sec-inner align-items-center d-flex justify-content-between wow fadeInUp mb-50" data-wow-delay="0.2s" data-wow-duration="0.4s">
                        <div class="sec-heading">
                            <div class="sub-inner mb-15">
                                <span class="sub-title">Featured</span>
                                <img class="heading-left-image" src="assets/images/icons/steps.png" alt="Steps" />
                            </div>
                        </div>
                        <div class="gamfi-btn-area">
                            <ul>
                                <li>
                                    <Link class="view-more black-shape" to="/sale-list">
                                        <span class="btn-text">View More</span>
                                        <i class="icon-arrow_right"></i>
                                        <span class="hover-shape1"></span>
                                        <span class="hover-shape2"></span>
                                        <span class="hover-shape3"></span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        {dataload ? (
                            <div className='col-md-12'>
                                <HashLoader size="100" color="#fff" cssOverride={{ "left": "50%", "textAlign": "center", "top": "50%" }} />

                            </div>
                        ) : (
                            <>
                                <div class="col-lg-4 col-md-6">

                                    <Link to={`/ember-presale/?chainid=56`}>
                                        <div class="project-item hover-shape-border">
                                            <div class="ribbon ribbon-top-right"><span>Presale</span></div>
                                            <div class="project-info d-flex">
                                                <a href="#sec">
                                                    <img src="assets\images\default_images\ember.jpeg" height="80px" width="80px" alt="Project-I" />
                                                </a>
                                                <div class="project-auother">
                                                    <h4 class="mb-10">Ember Token</h4>
                                                    <div class="dsc">1 BNB ~ 14,477,367.049 EMBER</div>
                                                </div>
                                            </div>
                                            <div class="project-content">
                                                <div class="project-header d-flex justify-content-between">
                                                    <div class="heading-title">
                                                        <h4>{currentTime < 1663430400 ? Math.floor((1663430400 - currentTime) / 86400) : '0'} Days Left</h4>
                                                    </div>
                                                    <div class="project-icon">
                                                        <img src={`../assets/images/${supportNetwork[56].image}`} width="40px" height="40px" alt="Project-I" />
                                                    </div>
                                                </div>
                                                <div class="project-media">
                                                    <ul class="project-listing">
                                                        <li>Min allocation <span>0.01 BNB</span></li>
                                                        <li>Max allocation <span>24 BNB</span></li>
                                                        <li>Targeted raise <span>398.5 BNB</span></li>
                                                        <li>Access type <span>Whitelist</span></li>
                                                        <li class="social-share">
                                                            Social
                                                            <ul class="social-icon-list">
                                                                <li><a href="https://t.me/ember_token_io" target="_blank" rel="noreferrer" ><i class="icon-telegram"></i></a></li>
                                                                <li><a href="https://twitter.com/EmberToken" target="_blank" rel="noreferrer"><i class="icon-twitter"></i></a></li>
                                                                <li><a href="https://discord.gg/BVFZ6gTcYn" target="_blank" rel="noreferrer"><i class="icon-discord"></i></a></li>
                                                                <li><a href="https://embertoken.io" target="_blank" rel="noreferrer"><i class="icon-world"></i></a></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <span class="border-shadow shadow-1"></span>
                                            <span class="border-shadow shadow-2"></span>
                                            <span class="border-shadow shadow-3"></span>
                                            <span class="border-shadow shadow-4"></span>
                                        </div>
                                    </Link>
                                </div>
                                {
                                    openSale.map((rowdata, index) => {
                                        console.log(rowdata);
                                        let chainSymbol = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].image : supportNetwork['default'].image
                                        let currencySymbol = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].symbol : supportNetwork['default'].symbol
                                        let dayleft = currentTime < rowdata.startTime ? Math.floor((rowdata.startTime - currentTime) / 86400) : currentTime > rowdata.startTime && currentTime < rowdata.endTime ? Math.floor((rowdata.endTime - currentTime) / 86400) : 0;
                                        
                                        return (
                                            <div class="col-lg-4 col-md-6" key={index}>

                                                <Link to={rowdata.ispresale === '0' ? `/presale-details/${rowdata.poolAddress}?chainid=${rowdata.chainId}` : rowdata.ispresale === '1' ? `/privatesale-details/${rowdata.poolAddress}?chainid=${rowdata.chainId}` : rowdata.ispresale === '2' ? `/fairlaunch-details/${rowdata.poolAddress}?chainid=${rowdata.chainId}` : '/'}>
                                                    <div class="project-item hover-shape-border">
                                                        <div class="ribbon ribbon-top-right"><span>{rowdata.ispresale === '0' ? 'Presale' : rowdata.ispresale === '1' ? 'PrivateSale' : rowdata.ispresale === '2' ? 'FairSale' : 'Presale'}</span></div>
                                                        <div class="project-info d-flex">
                                                            <a href="#sec">
                                                                <img src={rowdata.logourl} height="80px" width="80px" alt="Project-I" />
                                                            </a>
                                                            <div class="project-auother">
                                                                <h4 class="mb-10">{rowdata.tokenName}</h4>
                                                                <div class="dsc">1 {currencySymbol} ~ {formatPrice(rowdata.rate)} {rowdata.tokenSymbol}</div>
                                                            </div>
                                                        </div>
                                                        <div class="project-content">
                                                            <div class="project-header d-flex justify-content-between">
                                                                <div class="heading-title">
                                                                    <h4>{dayleft} Days Left</h4>
                                                                </div>
                                                                <div class="project-icon">
                                                                    <img src={`../assets/images/${chainSymbol}`} width="40px" height="40px" alt="Project-I" />
                                                                </div>
                                                            </div>
                                                            <div class="project-media">
                                                                <ul class="project-listing">
                                                                    <li>Min allocation <span>{rowdata.minContribution} {currencySymbol}</span></li>
                                                                    <li>Max allocation <span>{rowdata.maxContribution} {currencySymbol}</span></li>
                                                                    <li>Targeted raise <span>{rowdata.hardCap} {currencySymbol}</span></li>
                                                                    <li>Access type <span>{rowdata.useWhitelisting === 'true' ? 'Whitelist' : 'Public'}</span></li>
                                                                    <li class="social-share">
                                                                        Social
                                                                        <ul class="social-icon-list">
                                                                            <li><a href={rowdata.telegram} target="_blank" rel="noreferrer" ><i class="icon-telegram"></i></a></li>
                                                                            <li><a href={rowdata.twitter} target="_blank" rel="noreferrer"><i class="icon-twitter"></i></a></li>
                                                                            <li><a href={rowdata.discord} target="_blank" rel="noreferrer"><i class="icon-discord"></i></a></li>
                                                                            <li><a href={rowdata.website} target="_blank" rel="noreferrer"><i class="icon-world"></i></a></li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <span class="border-shadow shadow-1"></span>
                                                        <span class="border-shadow shadow-2"></span>
                                                        <span class="border-shadow shadow-3"></span>
                                                        <span class="border-shadow shadow-4"></span>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }

                            </>
                        )
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
