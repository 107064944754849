import { ethers } from "ethers";
import { simpleRpcProvider } from "./connectors";
import multicallAbi from '../json/multicall.json';
import { multicallAddress } from "./constant";
import { getWeb3 } from "./connectors";


export const getContract = (abi, address, library = undefined) => {
  const signerOrProvider = library ? library.getSigner() : simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getMulticallContract = () => {
  return getContract(multicallAbi, multicallAddress);
}

export const formatPrice = (num) => {
  //return parseFloat(num.toFixed(decimals)).toLocaleString();
  return new Intl.NumberFormat().format(num);
}

export const formatNumber = (num) => {
  //return parseFloat(num.toFixed(decimals)).toLocaleString();
  if (num === '' || isNaN(num)) return ''; 
  var e;
  if (Math.abs(num) < 1.0) {
     e = parseInt(num.toString().split('e-')[1]);
    if (e) {
        num *= Math.pow(10,e-1);
        num = '0.' + (new Array(e)).join('0') + num.toString().substring(2);
    }
  } else {
     e = parseInt(num.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        num /= Math.pow(10,e);
        num += (new Array(e+1)).join('0');
    }
  }

  return num.toString().substring(0,15);
}


export const getWeb3Contract = (abi, address, chainId) => {
  let web3 = getWeb3(chainId);
  return new web3.eth.Contract(abi, address);
}

export const mulDecimal = (amount, decimal) => {
  return ethers.utils.parseUnits(amount.toString(), decimal);
  // let web3 = getWeb3();
  // var result = (amount - Math.floor(amount)) !== 0;
  // if (parseFloat(amount) <= 0 || result ) {
  //   return ethers.BigNumber.from(amount.toString()).mul(ethers.BigNumber.from(10).pow(decimal));
  // }
  // else {
  //   return ethers.BigNumber.from(amount).mul(ethers.BigNumber.from(10).pow(decimal));
  // }
}


// function toFixed(x) {
//   var e = parseInt(x.toString().split('e-')[1]);
//   if (Math.abs(x) < 1.0) {
    
//     if (e) {
//         x *= Math.pow(10,e-1);
//         x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
//     }
//   } else {
//     if (e > 20) {
//         e -= 20;
//         x /= Math.pow(10,e);
//         x += (new Array(e+1)).join('0');
//     }
//   }
//   return x;
// }

