import React, { useEffect, useState } from 'react'
import { useCommonStats, useAccountStats } from './helper/useStats';
import Countdown, { zeroPad } from "react-countdown";
import { useWeb3React } from "@web3-react/core";
import { supportNetwork } from '../../../hooks/network';
import Button from 'react-bootstrap-button-loader';
import { formatPrice } from '../../../hooks/contractHelper';
import poolAbi from '../../../json/presalePool.json';
import { parseEther } from 'ethers/lib/utils';
import { toast } from 'react-toastify';
import { getWeb3 } from '../../../hooks/connectors';
import { getContract } from '../../../hooks/contractHelper';
import poolManagerAbi from '../../../json/poolManager.json';
import ReactPlayer from 'react-player/youtube'
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import Chart from "react-google-charts";
import { contract } from '../../../hooks/constant';


export default function ProjectDetails() {
    const [updater, setUpdater] = useState(1);
    const stats = useCommonStats(updater);
    const accStats = useAccountStats(updater);
    const [currentTime] = useState(Math.floor(new Date().getTime() / 1000.0));
    const [social, setSocial] = useState({});
    const { chainId, account, library } = useWeb3React();
    const [amount, setAmount] = useState(0);
    const [btndisabled, setBtndisabled] = useState(false);
    const [error_msg, setError_msg] = useState('');
    const [loading, setLoading] = useState(false);
    const [waddloading, setWaddloading] = useState(false);
    const [finalLoading, setFinalLoading] = useState(false);
    const [wcLoading, setWcLoading] = useState(false);
    const [ctLoading, setCtLoading] = useState(false);
    const [locklLoading, setLocklLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [refcopy, setRefcopy] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [unsetmodalShow, setUnsetmodalShow] = useState(false);
    const [editmodalShow, setEditmodalShow] = useState(false);
    const [insurance, setInsurance] = useState(false);
    const [whitelistAddress, setWhitelistAddress] = useState('');
    const [removeWhitelistAddress, setRemoveWhitelistAddress] = useState('');
    const search = useLocation().search;
    const queryChainId = new URLSearchParams(search).get('chainid');
    const [error, setError] = useState({
        logourl: "",
        website: "",
        facebook: "",
        twitter: "",
        github: "",
        telegram: "",
        instagram: "",
        discord: "",
        reddit: "",
        youtube: "",
        brief: "",
        blockstart: ""
    });

    const [editSocial, setEditSocial] = useState({
        logourl: "",
        website: "",
        blockstart: "",
        facebook: "",
        twitter: "",
        github: "",
        telegram: "",
        instagram: "",
        discord: "",
        reddit: "",
        youtube: "",
        brief: ""
    })

    useEffect(() => {
        function getDetails() {
            let details = stats.poolDetails.toString().split('$#$');
            const object = {
                logourl: details[0],
                website: details[1],
                blockstar: details[2],
                facebook: details[3],
                twitter: details[4],
                github: details[5],
                telegram: details[6],
                instagram: details[7],
                discord: details[8],
                reddit: details[9],
                youtube: details[10],
                brief: details[11]

            }

            setSocial(object);
            setEditSocial(object);
        }
        if (stats) {
            getDetails();
        }
    }, [stats]);

    const checkValidation = (input, inputValue) => {
        let terror = 0;
        let message = "";
        var reg;
        switch (input) {
            case "facebook":
            case "twitter":
            case "github":
            case "telegram":
            case "instagram":
            case "discord":
            case "reddit":
            case "youtube":
            case "blockstart":

                reg = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig);
                if (inputValue !== '' && !reg.test(inputValue)) {
                    terror += 1;
                    message = "Please Enter Valid url!";
                }
                else {
                    message = "";
                }
                break;

            case "logourl":
            case "website":
                reg = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig);
                if (!reg.test(inputValue)) {
                    terror += 1;
                    message = "Please Enter Valid url!";
                }
                else {
                    message = "";
                }
                break;
            default:
                terror += 0;
                break;
        }

        if (terror > 0) {
            setError({ ...error, [input]: message });
            return false;
        }
        else {
            setError({ ...error, [input]: "" });
            return true;
        }
    }

    const checkAllValidation = () => {
        let terror = 0;
        var reg;
        Object.keys(editSocial).map((key, index) => {
            switch (key) {
                case "facebook":
                case "twitter":
                case "github":
                case "telegram":
                case "instagram":
                case "discord":
                case "reddit":
                case "youtube":
                case "blockstart":

                    reg = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig);
                    if (editSocial[key] !== '' && !reg.test(editSocial[key])) {
                        terror += 1;
                    }

                    break;

                case "logourl":
                case "website":
                    reg = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig);
                    if (!reg.test(editSocial[key])) {
                        terror += 1;
                    }

                    break;
                default:
                    terror += 0;
                    break;
            }
            return true;
        });

        if (terror > 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const onChangeInput = (e) => {
        e.preventDefault();
        checkValidation(e.target.name, e.target.value);
        setEditSocial({ ...editSocial, [e.target.name]: e.target.value })
    }

    const handleEditSubmit = async (e) => {
        let check = checkAllValidation();
        if (check) {
            e.preventDefault();
            setEditLoading(true);
            try {

                if (account) {
                    let poolContract = getContract(poolAbi, stats.poolAddress, library);
                    let detailsset = `${editSocial.logourl}$#$${editSocial.website}$#$${editSocial.blockstart}$#$${editSocial.facebook}$#$${editSocial.twitter}$#$${editSocial.github}$#$${editSocial.telegram}$#$${editSocial.instagram}$#$${editSocial.discord}$#$${editSocial.reddit}$#$${editSocial.youtube}$#$${editSocial.brief}`;
                    let tx = await poolContract.updatePoolDetails(detailsset, {
                        'from': account,
                    });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success');
                                setUpdater(new Date());
                                setEditLoading(false);
                                setEditmodalShow(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setEditLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setEditLoading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please Connect to wallet !');
                    setEditLoading(false);
                }

            }
            catch (err) {
                toast.error(err.reason ? err.reason : err.message);
                setEditLoading(false);
            }
        }
        else {
            toast.error('Required all field or Enter wrong value  ! please check again');
        }

    }


    const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className="timer timer_1">
                    <ul>
                        <li>0<span>D</span></li>
                        <li>0<span>H</span></li>
                        <li>0<span>M</span></li>
                        <li>0<span>S</span></li>
                    </ul>
                </div>
            );
        } else {
            // Render a countdown
            return (
                <div className="timer timer_1">
                    <ul>
                        <li>{zeroPad(days, 2)}<span>D</span></li>
                        <li>{zeroPad(hours, 2)}<span>H</span></li>
                        <li>{zeroPad(minutes, 2)}<span>M</span></li>
                        <li>{zeroPad(seconds, 2)}<span>S</span></li>
                    </ul>
                </div>
            );
        }
    };

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
        setBtndisabled(true);

        if (isNaN(e.target.value)) {
            setError_msg('please enter valid amount');
            setBtndisabled(true);
        }

        else if (parseFloat(e.target.value) === 0 || e.target.value === '') {
            setError_msg('amount must be greater than zero');
            setBtndisabled(true);
        }
        else if (parseFloat(e.target.value) < parseFloat(stats.minContribution) || parseFloat(e.target.value) > parseFloat(stats.maxContribution)) {
            setError_msg(`amount must be between ${stats.minContribution} and ${stats.maxContribution}`);
            setBtndisabled(true);
        }
        else {
            setError_msg('');
            setBtndisabled(false);
        }
        return;
    }


    const handleMaxAmount = (e) => {
        e.preventDefault();
        let maxamount = parseFloat(accStats.balance) - parseFloat(0.01);
        if (parseFloat(maxamount) < parseFloat(stats.minContribution) || parseFloat(maxamount) > parseFloat(stats.maxContribution)) {
            setError_msg(`amount must be between ${stats.minContribution} and ${stats.maxContribution}`);
            setBtndisabled(true);
        }
        setAmount(maxamount.toFixed(4).toString());
    }

    const handleSubmitContribution = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (amount > 0 && (parseFloat(stats.maxContribution) > parseFloat(amount) || parseFloat(stats.minContribution) < parseFloat(amount))) {
                if (account) {
                    if (chainId) {
                        if (parseFloat(accStats.balance) >= parseFloat(amount)) {

                            let poolContract = getContract(poolAbi, stats.poolAddress, library);
                            let managerAddress = contract[chainId] ? contract[chainId].poolmanager : contract['default'].poolmanager
                            let poolManager = getContract(poolManagerAbi, managerAddress, library);
                            let currentRate = await poolManager.getUSDRate();
                            let totalValue = parseFloat(amount);
                            if (insurance) {
                                let insuranceValue = parseFloat(currentRate[0] / Math.pow(10, 18)) * 30;
                                totalValue += parseFloat(insuranceValue.toFixed(8));
                            }

                            let tx = await poolContract.contribute(insurance, {
                                'from': account,
                                value: parseEther(totalValue.toString())
                            });
                            const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                            toast.promise(
                                resolveAfter3Sec,
                                {
                                    pending: 'Waiting for confirmation 👌',
                                }
                            )

                            var interval = setInterval(async function () {
                                let web3 = getWeb3(chainId);
                                var response = await web3.eth.getTransactionReceipt(tx.hash);
                                if (response != null) {
                                    clearInterval(interval)
                                    if (response.status === true) {
                                        toast.success('success ! your last transaction is success 👍');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else if (response.status === false) {
                                        toast.error('error ! Your last transaction is failed.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else {
                                        toast.error('error ! something went wrong.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                }
                            }, 5000);

                        }
                        else {
                            toast.error('you don\'t have enough balance !');
                            setLoading(false);
                        }

                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Amount !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const handleWhitelistStatus = async (e) => {
        e.preventDefault();
        // try {
        if (account) {
            if (chainId) {
                let whitelist_status = e.target.value === '1' ? true : false;

                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.setWhitelisting(whitelist_status, {
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation 👌',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success 👍');
                            setUpdater(new Date());
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please select Smart Chain Network !');

            }
        }
        else {
            toast.error('Please Connect Wallet!');

        }

        // }
        // catch (err) {
        //     toast.error(err.reason);

        // }
    }

    const handleSetWhitelist = async (e) => {
        e.preventDefault();
        setWaddloading(true);
        try {
            const waddress = whitelistAddress.split(/\r?\n/);
            if (waddress.length > 0) {
                if (account) {
                    let poolContract = getContract(poolAbi, stats.poolAddress, library);

                    let tx = await poolContract.addWhitelistedUsers(waddress, {
                        'from': account,
                    });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please Connect to wallet !');
                    setWaddloading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Addess !');
                setWaddloading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setWaddloading(false);
        }
    }

    const handleRemoveWhitelist = async (e) => {
        e.preventDefault();
        setWaddloading(true);
        try {
            const waddress = removeWhitelistAddress.split(/\r?\n/);
            if (waddress.length > 0) {
                if (account) {
                    let poolContract = getContract(poolAbi, stats.poolAddress, library);

                    let tx = await poolContract.removeWhitelistedUsers(waddress, {
                        'from': account,
                    });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setWaddloading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please Connect to wallet !');
                    setWaddloading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Addess !');
                setWaddloading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setWaddloading(false);
        }
    }

    const handleFinalize = async (e) => {
        e.preventDefault();
        setFinalLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.finalize({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setFinalLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setFinalLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setFinalLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setFinalLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setFinalLoading(false);
        }
    }

    const handleWithdrawContribution = async (e) => {
        e.preventDefault();
        setWcLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.withdrawContribution({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setWcLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setWcLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setWcLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setWcLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setWcLoading(false);
        }
    }

    const handleClaimToken = async (e) => {
        e.preventDefault();
        setCtLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.claim({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setCtLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setCtLoading(false);
        }
    }

    const handleCancel = async (e) => {
        e.preventDefault();
        setLocklLoading(true);
        try {

            if (account) {
                let poolContract = getContract(poolAbi, stats.poolAddress, library);

                let tx = await poolContract.cancel({
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setLocklLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setLocklLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLocklLoading(false);
        }
    }

    return (
        <React.Fragment>
            {/* <!-- Breadcrumbs Section Start --> */}
            <div className="gamfi-breadcrumbs-section breadcrumbs-style2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="breadcrumbs-area sec-heading">
                                <div className="sub-inner">
                                    <a className="breadcrumbs-link" href="#sec">Project</a>
                                    <span className="sub-title">Project Details</span>
                                    <img className="heading-left-image" src="../assets/images/icons/steps.png" alt="StepsImage7" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumbs  Section End --> */}

            {/* <!-- Project Detaisl Content Start --> */}
            <div className="project-details-conent gamfi-about-secion pb-80 md-pb-20">
                <div className="container">
                    <div className="game-price-item hover-shape-inner">
                        <div className="game-price-inner">
                            <div className="total-price">
                                <div className="price-inner d-flex mb-45">
                                    <div className="image-icon">
                                        <img src={social.logourl} height="100" width="100px" alt="iconimage12" />
                                    </div>
                                    <div className="price-details">
                                        <h3 className="mb-15">{stats.tokenName} Presale</h3>
                                        <div className="dsc">price 1 {stats.currencySymbol} =  {formatPrice(stats.rate)} {stats.tokenSymbol}</div>
                                    </div>
                                </div>
                                <div className="all-raise mb-10"> Total Raise {parseFloat(stats.totalRaised).toFixed(2)} {stats.currencySymbol} ( {parseFloat(stats.percentageRaise).toFixed(2)}% )</div>
                            </div>
                            <div className="allocation-max text-center">
                                <img src={`../assets/images/${supportNetwork[queryChainId] ? supportNetwork[queryChainId].image : supportNetwork[chainId] ? supportNetwork[chainId].image : supportNetwork['default'].image}`} alt="iconimage12" height="50" width="50" />
                                <div className="allocation">Allocation: {stats.softCap} {stats.currencySymbol} Min</div>
                            </div>
                            <div className="targeted-raise">
                                <div className="all-raise mb-10">Sale {stats.startTime > currentTime ? 'Start In' : 'End In'}</div>
                                <div className="price-counter mb-48">
                                    <Countdown
                                        key={Math.floor(Math.random() * 10 + 1)}
                                        date={stats.startTime > currentTime ? stats.startTime * 1000 : stats.endTime * 1000}
                                        renderer={countdownrender}
                                    />

                                </div>
                                <div className="targeted-raise text-end">
                                    Targeted Raise {stats.hardCap} {stats.currencySymbol}
                                </div>
                            </div>
                        </div>
                        <div className="progress-inner">
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow="43" aria-valuemin="0" aria-valuemax="100" style={{ "width": `${parseFloat(stats.percentageRaise).toFixed(2)}%` }}>
                                </div>
                            </div>
                        </div>
                        <div class="game-price-inner">
                            <div class="total-price justify-content-start mt-4">
                                <div class="d-flex gap-1">
                                    {stats.audit && (stats.auditStatus ? (
                                        stats.auditLink ? (
                                            <a target="_blank" rel="noreferrer" href={stats.auditLink}><h4 class="tag-btn text-uppercase text-center bg-yellow">Audit : completed</h4></a>) :
                                            (<h4 class="tag-btn text-uppercase text-center bg-yellow">Audit : completed</h4>)
                                    ) : (
                                        <h4 class="tag-btn text-uppercase text-center bg-yellow">Audit : pending</h4>
                                    ))
                                    }
                                    {stats.kyc && (stats.kycStatus ? (
                                        stats.kycLink ? (
                                            <a target="_blank" rel="noreferrer" href={stats.kycLink}><h4 class="tag-btn text-uppercase text-center bg-purple">kyc : completed</h4></a>) :
                                            (<h4 class="tag-btn text-uppercase text-center bg-purple">kyc : completed</h4>)
                                    ) : (
                                        <h4 class="tag-btn text-uppercase text-center bg-purple">kyc : pending</h4>
                                    ))
                                    }

                                </div>
                            </div>
                            <div className='allocation-max justify-content-center'>
                                <div className='text-center'>STATUS : {
                                    stats.poolState === '2' ? <button className='badge bg-danger rounded-pill'>Canceled</button> :
                                        parseInt(stats.endTime) < Math.floor(new Date().getTime() / 1000.0) ? <button className='badge bg-danger rounded-pill'>Ended</button> :
                                            parseInt(stats.startTime) > Math.floor(new Date().getTime() / 1000.0) ? <button className='badge bg-info rounded-pill'>Incoming</button> :
                                                parseInt(stats.startTime) < Math.floor(new Date().getTime() / 1000.0) && parseInt(stats.endTime) > Math.floor(new Date().getTime() / 1000.0) ? <button className='badge bg-success rounded-pill'> LIVE </button> : ''
                                } </div>
                            </div>
                            <div className='targeted-raise justify-content-start'>
                                <div className="banner-bottom-content mt-40">
                                    <div className="btn-area" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        {/* <a className="readon white-shape-small" href="#sec">
                                    <span className="btn-text">Claim Token </span>
                                    <span className="hover-shape1"></span>
                                    <span className="hover-shape2"></span>
                                    <span className="hover-shape3"></span>
                                </a> */}
                                    </div>
                                    <div className="">
                                        <ul className="social-icon-list">
                                            {social.blockstar &&
                                                <li><a target="_blank" rel="noreferrer" href={social.blockstar}>
                                                    <img src="../assets/images/default_images/Untitleddesign.png" height="27" width="27" alt="footerscoal" /></a></li>
                                            }
                                            {social.telegram &&
                                                <li><a target="_blank" rel="noreferrer" href={social.telegram}><i class="fa-brands fa-telegram"></i></a></li>
                                            }
                                            {social.twitter &&
                                                <li><a target="_blank" rel="noreferrer" href={social.twitter}>
                                                    <i class="fa-brands fa-twitter"></i>
                                                </a></li>
                                            }
                                            {social.discord &&
                                                <li><a target="_blank" rel="noreferrer" href={social.discord}><i class="fa-brands fa-discord"></i></a></li>
                                            }
                                            {social.facebook &&
                                                <li><a target="_blank" rel="noreferrer" href={social.facebook}><i class="fa-brands fa-facebook"></i></a></li>
                                            }
                                            {social.github &&
                                                <li>
                                                    <a target="_blank" rel="noreferrer" href={social.github}>
                                                        <i class="fa-brands fa-github"></i>
                                                    </a></li>
                                            }
                                            {social.reddit &&
                                                <li><a target="_blank" rel="noreferrer" href={social.reddit}><i class="fa-brands fa-reddit"></i></a></li>
                                            }
                                            {social.website &&
                                                <li><a target="_blank" rel="noreferrer" href={social.website}><i className=" icon-world"></i></a></li>
                                            }
                                            {stats.governance && account && stats.governance.toLowerCase() === account.toLowerCase() &&
                                                <li>
                                                    <img src="../assets/images/key-solid.svg" alt="img-key" height="20" width="20" />
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                    </div>
                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            <div class="project-item">
                                <h4>Contributor Zone</h4>

                                <div class="project-form-list">
                                    <h5 class="mb-18">Balance: {accStats ? formatPrice(accStats.balance) : 0} {stats.currencySymbol}</h5>
                                    <div class="balance-form-area mb-27">
                                        <input type="text" value={amount} onChange={(e) => { handleChangeAmount(e) }} placeholder="Enter Amount" />
                                        <span class="max" onClick={(e) => { handleMaxAmount(e) }}>MAX</span>

                                        <div className="white-shape-small approve">
                                            <Button variant="none" disabled={btndisabled} loading={loading} type="button" onClick={(e) => { handleSubmitContribution(e) }}>Submit</Button>
                                            <span className="hover-shape1"></span>
                                            <span className="hover-shape2"></span>
                                            <span className="hover-shape3"></span>
                                        </div>

                                    </div>
                                    <h5 className='text-danger mb-47'><small>{error_msg}</small></h5>
                                    <div class="form-check mb-47">
                                        <input class="form-check-input" name="insurance" checked={insurance} type="checkbox" onClick={(e) => setInsurance(!insurance)} id="flexCheckDefault" />
                                        <div class="css-p1nhy6">
                                            <h5 class="form-check-label" for="flexCheckDefault">
                                                Purchase Insurance ({stats.insurcePrice} USD)({stats.insurcePrice} USD) <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                                                    <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"></path>
                                                </svg>
                                            </h5>

                                            <div class="rarity-legend css-1e07v95">
                                                BlockStar Protection Plan The BlockStar platform is the first of its kind to offer a rug and exploit protection plan which will pay back up to 100% of your investment into a vetted private or presale that rugs or has a code exploit within the first 60 days of launch.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="project-info">
                                    <h4 class="mb-15">Your Contribution</h4>
                                    <h3 class="mb-15">{accStats.contributionOf ? formatPrice(accStats.contributionOf) : '0'} <span class="buse">{stats.currencySymbol}</span></h3>
                                    {typeof accStats.contributionOf !== 'undefined' && accStats.contributionOf > 0 && stats.poolState === 0 &&
                                        <React.Fragment>
                                            <Button loading={wcLoading} className="btn btn-parimary" onClick={(e) => handleWithdrawContribution(e)}>Withdraw</Button>
                                            <span className='text-danger'>*10% fees charge for withdraw contribution</span>
                                        </React.Fragment>
                                    }
                                    {stats.poolState && stats.poolState === '1' && parseFloat(accStats.userAvalibleClaim) > 0 &&
                                        <React.Fragment>
                                            <h4 class="mb-15">Your Claimble Token</h4>
                                            <h3 class="mb-15">{accStats.userAvalibleClaim ? formatPrice(accStats.userAvalibleClaim) : '0'} <span class="buse">{stats.tokenSymbol}</span></h3>
                                            <Button loading={ctLoading} className="btn btn-parimary" onClick={(e) => handleClaimToken(e)}>Claim Token</Button>
                                        </React.Fragment>
                                    }
                                    {accStats.haveInsurance && <span>congratulations you have successfully registered for Pool Insurance!</span>}
                                </div>

                            </div>

                        </div>
                        <div className='col-md-6'>
                            <div class="project-item">
                                <div className='d-flex justify-content-between'>
                                    <h4>Admin Zone</h4>
                                    {stats.governance && account && stats.governance.toLowerCase() === account.toLowerCase() &&
                                        < a href="#sec" onClick={(e) => setEditmodalShow(!editmodalShow)} type="button">
                                            <img src="../assets/images/edit.png" className="ml-4" alt="img-edit" style={{ "height": "20px", "width": "20px" }} />
                                        </a>
                                    }
                                </div>
                                {stats.governance && account && stats.governance.toLowerCase() === account.toLowerCase() ? (
                                    <React.Fragment>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="mb-18">Sale Type</h5>

                                                    <div className="col-md-12 mt-4 mb-0">
                                                        <label>Fee Options</label>
                                                        <div className="form-check">
                                                            <span className="form-check-label">
                                                                <input type="radio" className="form-check-input" name="fees" value="1"
                                                                    checked={stats.useWhitelisting ? 'checked' : ''}
                                                                    onChange={(e) => handleWhitelistStatus(e)}
                                                                />Whitelist
                                                            </span>
                                                        </div>
                                                        <div className="form-check">
                                                            <span className="form-check-label">
                                                                <input type="radio" className="form-check-input" name="fees" value="2"
                                                                    onChange={(e) => handleWhitelistStatus(e)}
                                                                    checked={stats.useWhitelisting ? '' : 'checked'} />Public
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="project-info">
                                                        <button className='btn btn-primary mt-3' onClick={(e) => setModalShow(!modalShow)}>Add users whitelist</button><br />
                                                        <button className='btn btn-danger mt-3' onClick={(e) => setUnsetmodalShow(!unsetmodalShow)}>Remove whitelist user</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {stats.poolState === '0' && (stats.totalRaised === stats.hardCap || parseFloat(stats.hardCap - stats.totalRaised) < parseFloat(stats.minContribution) ||
                                                stats.totalRaised >= stats.softCap) && Math.floor(new Date().getTime() / 1000.0) >= stats.endTime &&
                                                <div class="row">
                                                    <div class="col text-center">
                                                        <Button className='btn btn-primary mt-3' loading={finalLoading} onClick={(e) => handleFinalize(e)}>finalize</Button>
                                                    </div>
                                                </div>
                                            }
                                            {stats.poolState === '0' &&
                                                <div class="row">
                                                    <div class="col text-center">
                                                        <Button className='btn btn-primary mt-3' loading={locklLoading} onClick={(e) => handleCancel(e)}>Cancel</Button>
                                                    </div>
                                                </div>
                                            }
                                        </div>


                                    </React.Fragment>
                                ) : (
                                    <div class="project-form-list">
                                        <h5 class="mb-18">You are not onwer of pool</h5>
                                    </div>
                                )
                                }
                            </div>

                        </div>

                    </div>
                    <div className="row mt-30">
                        <div className="col-md-6">
                            <div className="project-item">
                                <div className="project-info">
                                    <h4 className="mb-30">Sale Info <img src="../assets/images/project/project-heading-image.png" alt="project" /></h4>
                                </div>
                                <div className="project-content">
                                    <div className="project-media">
                                        <ul className="project-listing">

                                            <li>Sale Type <span>Private Sale</span></li>
                                            <li>Min. Allocation <span>{stats.minContribution} {stats.currencySymbol}</span></li>
                                            <li>Max. Allocation <span>{stats.maxContribution} {stats.currencySymbol}</span></li>
                                            <li>Token Price <span>1 {stats.currencySymbol} = {formatPrice(stats.rate)} {stats.tokenSymbol}</span></li>
                                            <li>Access type <span>{stats.useWhitelisting ? 'Whitelist' : 'Public'}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="project-item">
                                <div className="project-info d-flex">
                                    <h4 className="mb-20">Token Info <img src="../assets/images/project/project-heading-image.png" alt="project" /></h4>
                                </div>
                                <div className="project-content">
                                    <div className="project-media">
                                        <ul className="project-listing">
                                            <li>Token Name <span>{stats.tokenName}</span></li>
                                            <li>Token Symbol <span>{stats.tokenSymbol}</span></li>
                                            <li>Decimals <span>{stats.tokenDecimal}</span></li>
                                            <li>Address
                                                <span>
                                                    {stats.token.toString().slice(0, 15)}...
                                                    <CopyToClipboard text={stats.token} onCopy={() => {
                                                        setRefcopy(true);
                                                        setTimeout(() => {
                                                            setRefcopy(false);
                                                        }, 2000)
                                                    }}>
                                                        <img src="../assets/images/project/icon.png" alt="project" />
                                                    </CopyToClipboard>
                                                    <span>{refcopy && 'copied'}</span>

                                                </span>
                                            </li>
                                            <li>Total Supply <span>{formatPrice(stats.tokenSupply)} {stats.tokenSymbol}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Project Summary  Start--> */}
                    <div className="row mt-50" data-spy="scroll" data-target="#myScrollspy" data-offset="20">
                        <div className="col-md-4">
                            <div className="project-summary-menu project_summary_menu_BgGridient" id="myScrollspy">
                                <div id="ProjectSammeryTabs" className="ProjectSammeryTabsSect">
                                    <div className="active ProjectSummeryTabBtn  ProjectSummeryTabBtn1" data-target="#project_summary"><a href="#project_summary">Project Summary <img src="../assets/images/project/menu-image.png" alt="project" /></a></div>

                                    <div className="ProjectSummeryTabBtn ProjectSummeryTabBtn2" data-target="#Schedule"><a href="#Schedule">Other Details <img src="../assets/images/project/menu-image.png" alt="project" /></a></div>
                                </div>
                                <div class="mt-5">
                                    <div class="row">
                                        <div className='text-center'>Scheduled Marketing/Ama's</div>
                                        {stats.partnerList && stats.partnerList.map((rowdata, index) => {
                                            return (

                                                <div className="col-md-6 mt-5 text-center" key={index}>
                                                    <div>
                                                        <img style={{ "height": "50px", "width": "50px" }} src={rowdata[4] ? rowdata[4] : 'https://ipfs.infura.io/ipfs/QmVm1voCw4Lf4gmccA2emczC1oC97L35wrnLfmKtAwcP6a'} alt="img-profileama" className="image--cover" />
                                                    </div>
                                                    <span htmlFor="html">{rowdata[5] ? rowdata[5] : 'BlockStart Partner'}</span><br />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='col-12 d-flex  mt-5 justify-content-center chart-style' id="chart_div">
                                    <Chart
                                        width={"380px"}
                                        height={"300px"}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ["Task", "Hours per Day"],
                                            ["Presale", parseFloat(stats.PresalePer)],
                                            ["Liquidity", parseFloat(stats.LiquidityPer)],
                                            ["Unlocked", 100 - (parseFloat(stats.PresalePer) + parseFloat(stats.LiquidityPer) + parseFloat(stats.BurntPer) + parseFloat(stats.LockPer))],
                                            ["Burnt", parseFloat(stats.BurntPer)],
                                            ["Lock", parseFloat(stats.LockPer)]
                                        ]}
                                        options={{
                                            backgroundColor: '#000'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 pl-35 sm-pl-15 sm-pt-40">
                            <div className="project_summarySectScrllab">
                                <div className="project-summary-content" id="project_summary">
                                    <div className="project-summary-information">
                                        <h4 className="title2 mb-15">Project Summary</h4>
                                        <div className='mb-5' style={{ "whiteSpace": "pre-wrap" }}>{social.brief ? social.brief : ''}</div>
                                        {social.youtube &&
                                            <div className="project-video-area" >
                                                <ReactPlayer style={{
                                                    "maxWidth": "100%",
                                                    "height": "auto"
                                                }} controls={true} loop={false} url={social.youtube} />
                                                {/* <img src="../assets/images/project/project-video.jpg" alt="ProjectImage12" />
                                                <a className="video-icons" href={social.youtube}><i className="icon-play"></i></a> */}
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="ScrollSpy_sections" id="Schedule">
                                    <h4 className="mb-20">Other Details</h4>
                                    <table className="mb-55">
                                        <tr>
                                            <td>Unsold Tokens</td>
                                            <td>{stats.refundType === '0' ? 'Refund' : 'Burn'}</td>
                                        </tr>
                                        <tr>
                                            <td>First Release For Presale</td>
                                            <td>{stats.tgeTokenReleasePer > 0 ? stats.tgeTokenReleasePer : 100} %</td>
                                        </tr>
                                        {stats.cycle > 0 &&
                                            <tr>
                                                <td>Tokens release each cycle</td>
                                                <td>{stats.eachvestingPer}% each {stats.tgeLockDuration / (60 * stats.cycle)} minutes</td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            {/* <!-- Project Detaisl Content End --> */}
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <h5>Add Whitelist Address</h5>
                            <Form.Control as="textarea" onChange={(e) => { setWhitelistAddress(e.target.value) }} rows={8} placeholder="Insert address: separate with breaks line.
            Ex:
            0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6
            0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1
            0x968136BB860D9534aF1563a7c7BdDa02B1A979C2" value={whitelistAddress} />
                        </Form.Group>
                        <Button variant="none" className="btn btn-success" loading={waddloading} onClick={(e) => { handleSetWhitelist(e) }}>Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={unsetmodalShow}
                onHide={() => setUnsetmodalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <h5>Remove Whitelist Address</h5>
                            <Form.Control as="textarea" onChange={(e) => { setRemoveWhitelistAddress(e.target.value) }} rows={8} placeholder="Insert address: separate with breaks line.
            Ex:
            0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6
            0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1
            0x968136BB860D9534aF1563a7c7BdDa02B1A979C2" value={removeWhitelistAddress} />
                        </Form.Group>
                        <Button variant="none" className="btn btn-success" loading={waddloading} onClick={(e) => { handleRemoveWhitelist(e) }}>Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={editmodalShow}
                onHide={() => setEditmodalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Logo URL<span className='text-danger'>*</span></label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.logourl} name="logourl" placeholder="e.g. https://blockstar.site/pwa_image/blockstar_pwa.png" />
                                    <small className='text-danger'>{error.logourl}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Website*<span className='text-danger'>*</span></label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.website} name="website" placeholder="e.g. https://blockstar.site" />
                                    <small className='text-danger'>{error.website}</small><br />
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>BlockStar Group</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.blockstart} name="blockstart" placeholder="e.g. https://blockstar.site/page/view-group-profile" />
                                    <small className='text-danger'>{error.blockstart}</small><br />
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Facebook</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.facebook} name="facebook" placeholder="e.g. https://www.facebook.com/" />
                                    <small className='text-danger'>{error.facebook}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Twitter</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.twitter} name="twitter" placeholder="e.g. https://twitter.com/" />
                                    <small className='text-danger'>{error.twitter}</small><br />
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Github</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" value={editSocial.github} name="github" placeholder="e.g. https://github.com/" />
                                    <small className='text-danger'>{error.github}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Telegram</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="telegram" value={editSocial.telegram} placeholder="e.g. https://t.me/BlockStar_Social_Media" />
                                    <small className='text-danger'>{error.telegram}</small><br />
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Instagram</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="instagram" value={editSocial.instagram} placeholder="e.g. https://www.instagram.com/" />
                                    <small className='text-danger'>{error.instagram}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Discord</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="discord" value={editSocial.discord} placeholder="e.g. https://discord.com/" />
                                    <small className='text-danger'>{error.discord}</small><br />
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Reddit</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="reddit" value={editSocial.reddit} placeholder="e.g. https://reddit.com/" />
                                    <small className='text-danger'>{error.reddit}</small><br />
                                </div>
                            </div>
                            <div className="col-md-6 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Youtube Video</label>
                                    <input className="form-control" onChange={(e) => onChangeInput(e)} type="text" name="youtube" value={editSocial.youtube} placeholder="e.g. https://www.youtube.com/watch?v=BHACKCNDMW8" />
                                    <small className='text-danger'>{error.youtube}</small><br />
                                </div>
                            </div>

                            <div className="col-md-12 mt-4 mb-0">
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea type="text" name="brief" onChange={(e) => onChangeInput(e)} className="brief" placeholder="Project Brief" >{editSocial.brief}</textarea>
                                    <small className='text-danger'>{error.brief}</small><br />
                                </div>
                            </div>

                            <div className="col-md-12 mt-4 mb-0">
                                <Button className="btn btn-success" loading={editLoading} onClick={(e) => handleEditSubmit(e)}>submit</Button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </React.Fragment >
    )
}
