import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
    NoEthereumProviderError
} from "@web3-react/injected-connector";

import { useEffect, useState } from "react";
import { injected, walletconnect, coinbaseWallet } from "../hooks/connectors";
import Modal from 'react-bootstrap/Modal';
import { trimAddress } from '../hooks/constant';
import { supportNetwork } from "../hooks/network";
import useEagerConnect from '../hooks/useWeb3';




export const Connect = function () {
    const context = useWeb3React();
    const { connector, chainId, account, activate, deactivate, active, error } = context;
    const [show, setShow] = useState(false);
    const [networkshow, setNetworkshow] = useState(false);

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();
    useEagerConnect();
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);


    function getErrorMessage(error) {

        if (error instanceof NoEthereumProviderError) {
            return "Metamask not deteced";
        }
        if (error instanceof UnsupportedChainIdError) {
            return <span className="btn-text" onClick={(e) => setNetworkshow(!networkshow)}>
                <img src="../assets/images/wrong-network.svg" alt="wrong-network" height="17px" width="17px" className="mx-2" />Wrong Network</span>;
        }

        deactivate(injected);
    }

    const activating = (connection) => connection === activatingConnector;
    const connected = (connection) => connection === connector;

    const switchNetwork = (networkid) => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${networkid.toString(16)}` }]
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <li>
                <button type="button" className="readon black-shape-big connectWalletBtnforMobile" onClick={() => {
                    setNetworkshow(!networkshow);
                }}>
                    <span className="btn-text">
                        <img src={`../assets/images/${(chainId && supportNetwork[chainId]) ? supportNetwork[chainId].image : supportNetwork['default'].image}`} alt="Switch Network" className="sc-dPaNzc fqbJCS" style={{ "width": "20px" }} />
                        {(chainId && supportNetwork[chainId]) ? supportNetwork[chainId].name : supportNetwork['default'].name}
                        </span>
                    <span className="hover-shape1"></span>
                    <span className="hover-shape2"></span>
                    <span className="hover-shape3"></span>
                </button>
            </li>
            <li>

                {
                    error &&
                    <button type="button" className="readon black-shape-big connectWalletBtnforMobile" onClick={() => {
                        setActivatingConnector();
                    }}>
                        <span className="btn-text">{getErrorMessage(error)}</span>
                        <span className="hover-shape1"></span>
                        <span className="hover-shape2"></span>
                        <span className="hover-shape3"></span>
                    </button>
                }
                {!error &&
                    <>


                        {active && (connected(injected) || connected(walletconnect) || connected(coinbaseWallet)) &&
                            <button type="button" className="readon black-shape-big connectWalletBtnforMobile" >

                                <span className="btn-text" onClick={() => {
                                    setActivatingConnector();
                                    deactivate(injected);
                                    deactivate(walletconnect);
                                    deactivate(coinbaseWallet);

                                }} >{account && trimAddress(account)}</span>


                                <span className="hover-shape1"></span>
                                <span className="hover-shape2"></span>
                                <span className="hover-shape3"></span>
                            </button>


                        }
                        {!active && (!connected(injected) || !connected(walletconnect) || !connected(coinbaseWallet)) &&
                            <button type="button" className="readon black-shape-big connectWalletBtnforMobile" onClick={() => {
                                setShow(!show);
                            }}>
                                <img src="assets/images/icons/connect.png" alt="Icon" />
                                {(activating(injected) || activating(walletconnect) || activating(coinbaseWallet)) && <span className="btn-text">Connecting...</span>}
                                {(!activating(injected) || !activating(walletconnect) || !activating(coinbaseWallet)) && <span className="btn-text">Connect wallet</span>}
                                <span className="hover-shape1"></span>
                                <span className="hover-shape2"></span>
                                <span className="hover-shape3"></span>
                            </button>
                        }
                    </>
                }
            </li>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="sm"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Connect to a wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-dark modal-btn-connect" onClick={() => {
                                    activate(injected);
                                    setShow(false);
                                }}>
                                    <div className="div-modal-btn">
                                        <img src="../assets/images/meta-mask.png" alt="Meta-mask-Im" className="modal-img" />
                                        <div className="text-modal-line">Metamask</div>
                                    </div>
                                </button>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-dark modal-btn-connect" onClick={() => {
                                    activate(walletconnect);
                                    setShow(false);
                                }}>
                                    <div className="div-modal-btn">
                                        <img src="../assets/images/wallet.png" alt="walletConnect" className="modal-img" />
                                        <div className="text-modal-line">WalletConnect</div>
                                    </div>
                                </button>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-dark modal-btn-connect" onClick={() => {
                                    activate(coinbaseWallet);
                                    setShow(false);
                                }}>
                                    <div className="div-modal-btn">
                                        <img src="../assets/images/coinbase.png" alt="coinbase" className="modal-img" />
                                        <div className="text-modal-line">Coinbase</div>
                                    </div>
                                </button>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-dark modal-btn-connect" onClick={() => {
                                    activate(injected);
                                    setShow(false);
                                }}>
                                    <div className="div-modal-btn">
                                        <img src="../assets/images/trust.png" alt="coinbase" className="modal-img" />
                                        <div className="text-modal-line">TrustWallet</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={networkshow}
                onHide={() => setNetworkshow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                size="lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            {Object.keys(supportNetwork).map(function (key) {
                                if (key === 'default') return (<React.Fragment key={key}></React.Fragment>);
                                return (
                                    <div className="col-12" key={key}>
                                        <button className="btn btn-dark modal-btn-connect" onClick={() => {
                                            switchNetwork(supportNetwork[key].chainId);
                                            setNetworkshow(false);
                                        }}>
                                            <div className="div-modal-btn">
                                                <img src={`../assets/images/${supportNetwork[key].image}`} alt="Meta-mask-Im" className="modal-img" />
                                                <div className="text-modal-line">{supportNetwork[key].name}</div>
                                            </div>
                                        </button>
                                    </div>
                                )
                            })
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default Connect;