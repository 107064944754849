import { createContext, useEffect, useState } from 'react';
import { tokenList } from '../TokenList';
import { useWeb3React } from "@web3-react/core";
import { MulticallContractWeb3, multiCallContractConnect } from '../../../hooks/useContracts';
import { getWeb3 } from '../../../hooks/connectors';
import tokenAbi from '../../../json/token.json';
import swapFactoryAbi from '../../../json/swapFactory.json';
import pairAbi from '../../../json/lpabi.json';
import { formatNumber } from '../../../hooks/contractHelper';


export const Context = createContext();
export const factoryAddress = "0x2492f0d01b94ef7b8c9c1689f2faa4b8ba7f7d92"
export const routerAddress = "0x5009633c920acf842e3845e3bf4e4c1fc5178980"
export const WBNBAddress = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";

export const ContextProvider = ({ children }) => {
    const [fromToken, setFromToken] = useState(tokenList[0]);
    const [toToken, setToToken] = useState('');
    const [show, setShow] = useState(false);
    const [settingshow, setSettingShow] = useState(false);
    const [isFrom, setIsFrom] = useState(true);
    const { chainId, account } = useWeb3React();
    const [tokenBal, setTokenBal] = useState([]);
    const [selectedBal, setSelectedBal] = useState(0);
    const [selectedToBal, setSelectedToBal] = useState(0);
    const [toFromPrice, setToFromPrice] = useState(0);
    const [fromtoPrice, setFromtoPrice] = useState(0);
    const [poolSharePer, setPoolSharePer] = useState(0);
    const [fromAmount, setFromAmount] = useState(0);
    const [toAmount, setToAmount] = useState(0);
    const [isPairExist, setIsPairExist] = useState(false);
    const [userTotalLp, setUserTotalLp] = useState(0);
    const [userFromLp, setUserFromLp] = useState(0);
    const [userToLp, setUserToLp] = useState(0);
    const [approveFrom, setApproveFrom] = useState(false);
    const [approveTo, setApproveTo] = useState(false);
    const [currentPair, setCurrentPair] = useState(false);


    useEffect(() => {
        async function fetchBalance() {

            if (account && chainId) {
                try {
                    let mc = MulticallContractWeb3(chainId);
                    let mcc = multiCallContractConnect(chainId);
                    let web3 = await getWeb3(chainId);
                    if (tokenList && tokenList.length > 0) {
                        let callList = [];
                        tokenList.map((rowdata, index) => {
                            if (rowdata.address && typeof rowdata.address !== 'undefined' && rowdata.address !== '') {
                                let tokenContract = new web3.eth.Contract(tokenAbi, rowdata.address);
                                callList[index] = tokenContract.methods.balanceOf(account);
                            }
                            else {
                                callList[index] = mcc.methods.getEthBalance(account);
                            }
                            return true;
                        })

                        let balanceData = await mc.aggregate(callList);
                        setTokenBal(balanceData);
                    }
                    else {
                        setTokenBal([]);
                    }
                }
                catch (err) {
                    console.log(err.message);
                    setTokenBal([]);
                }
            }
            else {
                setTokenBal([]);
            }

        }
        fetchBalance();
    }, [account, chainId])

    useEffect(() => {
        async function fetchBalance() {
            if (account) {
                try {
                    let mc = MulticallContractWeb3(chainId);
                    let web3 = await getWeb3(chainId);
                    if (fromToken.address && typeof fromToken.address !== 'undefined' && fromToken.address !== '') {

                        let tokenContract = new web3.eth.Contract(tokenAbi, fromToken.address);
                        let balanceData = await mc.aggregate([tokenContract.methods.balanceOf(account)]);
                        setSelectedBal(parseFloat(balanceData[0] / Math.pow(10, fromToken.decimals)).toFixed(3))
                    }
                    else {
                        let mcc = multiCallContractConnect(chainId);
                        let balanceData = await mc.aggregate([mcc.methods.getEthBalance(account)]);
                        setSelectedBal(parseFloat(balanceData[0] / Math.pow(10, 18)).toFixed(3))
                    }


                }
                catch (err) {
                    console.log(err.message);
                    setSelectedBal(0);
                }
            }
            else {
                setSelectedBal(0);
            }

        }
        fetchBalance();
        fetchPoolRate();
        // eslint-disable-next-line
    }, [fromToken, account, chainId, fromAmount, toAmount])

    useEffect(() => {
        async function fetchBalance() {
            if (account) {
                try {
                    let mc = MulticallContractWeb3(chainId);
                    let web3 = await getWeb3(chainId);
                    if (toToken.address && typeof toToken.address !== 'undefined' && toToken.address !== '') {

                        let tokenContract = new web3.eth.Contract(tokenAbi, toToken.address);
                        let balanceData = await mc.aggregate([tokenContract.methods.balanceOf(account)]);
                        setSelectedToBal(parseFloat(balanceData[0] / Math.pow(10, toToken.decimals)).toFixed(3))
                    }
                    else {
                        let mcc = multiCallContractConnect(chainId);
                        let balanceData = await mc.aggregate([mcc.methods.getEthBalance(account)]);
                        setSelectedToBal(parseFloat(balanceData[0] / Math.pow(10, 18)).toFixed(3))
                    }

                    fetchPoolRate();
                }
                catch (err) {
                    console.log(err.message);
                    setSelectedToBal(0);
                }
            }
            else {
                setSelectedToBal(0);
            }

        }
        fetchBalance();
        fetchPoolRate();
        // eslint-disable-next-line
    }, [toToken, account, chainId, fromAmount, toAmount])

    const fetchPoolRate = async () => {
        try {
            let mc = MulticallContractWeb3(chainId);
            let web3 = await getWeb3(chainId);
            let factoryContract = new web3.eth.Contract(swapFactoryAbi, factoryAddress);
            if (fromToken.decimals && typeof fromToken.decimals !== 'undefined' && toToken.decimals && typeof toToken.decimals !== 'undefined') {

                let pairdata = await mc.aggregate(
                    [
                        factoryContract.methods.getPair(toToken.address ? toToken.address : WBNBAddress, fromToken.address ? fromToken.address : WBNBAddress)
                    ]
                );

                if (pairdata.length > 0 && pairdata[0] !== '0x0000000000000000000000000000000000000000') {
                    setCurrentPair(pairdata[0]);
                    let pairContract = new web3.eth.Contract(pairAbi, pairdata[0]);
                    let tokenContract = new web3.eth.Contract(tokenAbi, fromToken.address ? fromToken.address : WBNBAddress);
                    let pairInfoData = await mc.aggregate(
                        account ? [
                            pairContract.methods.getReserves(),
                            pairContract.methods.token0(),
                            pairContract.methods.token1(),
                            tokenContract.methods.balanceOf(pairdata[0]),
                            pairContract.methods.balanceOf(account)

                        ] :
                            [
                                pairContract.methods.getReserves(),
                                pairContract.methods.token0(),
                                pairContract.methods.token1(),
                                tokenContract.methods.balanceOf(pairdata[0]),
                            ]
                    );


                    if ((pairInfoData[1].toLowerCase() === fromToken.address.toLowerCase()) || (fromToken.address === '' && pairInfoData[1].toLowerCase() === WBNBAddress.toLowerCase())) {
                        if(parseFloat(fromToken.decimals) >= parseFloat(toToken.decimals)){
                            let diffDeci = parseFloat(fromToken.decimals) - parseFloat(toToken.decimals); 
                            setToFromPrice(formatNumber((pairInfoData[0][1] / pairInfoData[0][0]) * Math.pow(10 ,diffDeci )))
                            setFromtoPrice(formatNumber((pairInfoData[0][0] / pairInfoData[0][1]) / Math.pow(10, diffDeci)))
                        }
                        else{
                            let diffDeci = parseFloat(toToken.decimals) - parseFloat(fromToken.decimals); 
                            setToFromPrice(formatNumber((pairInfoData[0][1] / pairInfoData[0][0]) / Math.pow(10 ,diffDeci )))
                            setFromtoPrice(formatNumber((pairInfoData[0][0] / pairInfoData[0][1]) * Math.pow(10, diffDeci)))
                        }
                    }
                    else {
                        if(parseFloat(fromToken.decimals) >= parseFloat(toToken.decimals)){
                            let diffDeci = parseFloat(fromToken.decimals) - parseFloat(toToken.decimals); 
                            setToFromPrice(formatNumber((pairInfoData[0][0] / pairInfoData[0][1]) * Math.pow(10 ,diffDeci )))
                            setFromtoPrice(formatNumber((pairInfoData[0][1] / pairInfoData[0][0]) / Math.pow(10, diffDeci)))
                        }
                        else{
                            let diffDeci = parseFloat(toToken.decimals) - parseFloat(fromToken.decimals); 
                            setToFromPrice(formatNumber((pairInfoData[0][0] / pairInfoData[0][1]) / Math.pow(10 ,diffDeci )))
                            setFromtoPrice(formatNumber((pairInfoData[0][1] / pairInfoData[0][0]) * Math.pow(10, diffDeci)))
                        }
                        
                    }

                    if (fromAmount > 0 || toAmount > 0) {
                        let poolBalance = parseFloat(pairInfoData[3] / Math.pow(10, fromToken.decimals ? fromToken.decimals : 18)) + parseFloat(fromAmount);
                        setPoolSharePer(formatNumber((parseFloat(fromAmount) * 100) / parseFloat(poolBalance)));
                    }
                    else {
                        setPoolSharePer(0);
                    }

                    if (account) {
                        let token0Contract = new web3.eth.Contract(tokenAbi, pairInfoData[1]);
                        let token1Contract = new web3.eth.Contract(tokenAbi, pairInfoData[2]);
                        let userInfo = await mc.aggregate(
                            [
                                pairContract.methods.balanceOf(account), //0
                                pairContract.methods.totalSupply(), //1
                                token0Contract.methods.balanceOf(pairdata[0]), //2
                                token1Contract.methods.balanceOf(pairdata[0]), //3
                                token0Contract.methods.decimals(), //4
                                token1Contract.methods.decimals(), //5
                                pairContract.methods.decimals(), //6
                                token0Contract.methods.allowance(account, routerAddress), //7
                                token1Contract.methods.allowance(account, routerAddress) //8
                            ]
                        );

                        let userHavePer = (userInfo[0] * 100) / userInfo[1];
                        let countToken0 = parseFloat((userInfo[2] / Math.pow(10, userInfo[4]) * userHavePer) / 100).toFixed(5);
                        let countToken1 = parseFloat((userInfo[3] / Math.pow(10, userInfo[5]) * userHavePer) / 100).toFixed(5);

                        setUserTotalLp(parseFloat(userInfo[0] / Math.pow(10, userInfo[6])).toFixed(8));
                        setUserFromLp(fromToken.address.toLowerCase() === pairInfoData[1].toLowerCase() ? parseFloat(countToken0).toFixed(8) : parseFloat(countToken1).toFixed(8));
                        setUserToLp(toToken.address.toLowerCase() === pairInfoData[1].toLowerCase() ? parseFloat(countToken0).toFixed(8) : parseFloat(countToken1).toFixed(8));
                        setApproveFrom(
                            fromToken.address === '' ? true : (fromToken.address.toLowerCase() === pairInfoData[1].toLowerCase()) ?
                                userInfo[7] / Math.pow(10, userInfo[4]) > 100000000 ? true : false :
                                userInfo[8] / Math.pow(10, userInfo[5]) > 100000000 ? true : false
                        );
                        setApproveTo(
                            toToken.address === '' ? true : (toToken.address.toLowerCase() === pairInfoData[1].toLowerCase()) ?
                                userInfo[7] / Math.pow(10, userInfo[4]) > 100000000 ? true : false :
                                userInfo[8] / Math.pow(10, userInfo[5]) > 100000000 ? true : false
                        );
                    }
                    else {
                        setUserTotalLp(0);
                        setUserFromLp(0);
                        setUserToLp(0);
                        setApproveFrom(false);
                        setApproveTo(false);
                        // setToFromPrice(0);
                        // setFromtoPrice(0);

                    }

                    setIsPairExist(true);

                }
                else if (pairdata[0] === '0x0000000000000000000000000000000000000000') {
                    setIsPairExist(false);
                    if (fromAmount > 0 || toAmount > 0) {
                        setPoolSharePer(100);
                        setToFromPrice(fromAmount)
                        toAmount > 0 ? setFromtoPrice(parseFloat(fromAmount / toAmount).toFixed(8)) : setFromtoPrice(0);
                    }

                    setUserTotalLp(0);
                    setUserFromLp(0);
                    setUserToLp(0);


                }

            }
            else {
                setPoolSharePer(0);
                setToFromPrice(0)
                setFromtoPrice(0)
            }
        }
        catch (err) {
            console.log(err.message);
            setPoolSharePer(0);
            setToFromPrice(0)
            setFromtoPrice(0)
        }
    }

    return (
        <Context.Provider
            value={{
                fromToken,
                setFromToken,
                toToken,
                setToToken,
                show,
                setShow,
                isFrom,
                setIsFrom,
                tokenBal,
                selectedBal,
                selectedToBal,
                toFromPrice,
                setToFromPrice,
                fromtoPrice,
                setFromtoPrice,
                poolSharePer,
                setPoolSharePer,
                fromAmount,
                toAmount,
                setFromAmount,
                setToAmount,
                isPairExist,
                userTotalLp,
                userFromLp,
                userToLp,
                approveFrom,
                setApproveFrom,
                approveTo,
                setApproveTo,
                settingshow,
                setSettingShow,
                currentPair
            }}
        >{children}</Context.Provider>
    )
}

