import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import presalePoolAbi from '../../../../json/FairPool.json';
import poolManagerAbi from '../../../../json/poolManager.json';
import lockAbi from '../../../../json/lockabi.json';
import { MulticallContractWeb3, multiCallContractConnect } from "../../../../hooks/useContracts";
import { getWeb3 } from "../../../../hooks/connectors";
import tokenAbi from '../../../../json/token.json';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { contract } from "../../../../hooks/constant";
import { supportNetwork } from "../../../../hooks/network";
import axios from "axios";
import { Api_Baseurl } from "../../../../hooks/constant";



export const useCommonStats = (update) => {
  const context = useWeb3React();
  const { chainId } = context;
  let history = useHistory();

  // let poolAddress = "0x6816e27bca20fbbe779ca9725d48c1e01a02943c";

  const location = useLocation();
  let urlAddress = location.pathname.split('/').pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get('chainid');

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);

  let poolContract = new web3.eth.Contract(presalePoolAbi, urlAddress);

  const [stats, setStats] = useState({
    endTime: 0,
    startTime: 0,
    softCap: 0,
    liquidityLockDays: 0,
    liquidityPercent: 0,
    liquidityUnlockTime: 0,
    poolDetails: 0,
    poolState: 0,
    rate: 0,
    token: 0,
    totalClaimed: 0,
    totalRaised: 0,
    tokenName: '',
    tokenDecimal: 0,
    tokenSymbol: 0,
    tokenSupply: 0,
    percentageRaise: 0,
    refundType: 0,
    poolAddress: 0,
    governance: 0,
    kyc: false,
    audit: false,
    auditStatus: false,
    kycStatus: false,
    partnerList: [],
    totalPresaleToken: 0,
    insurcePrice : 0,
    kycLink: '#',
    auditLink: '#',
    currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
  let poolManagerAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].poolmanager : contract['default'].poolmanager;
  let pmc = new web3.eth.Contract(poolManagerAbi, poolManagerAddress);
  let lockAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].lockAddress : contract['default'].lockAddress;
  let lmc = new web3.eth.Contract(lockAbi, lockAddress);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          poolContract.methods.endTime(),
          poolContract.methods.startTime(),
          poolContract.methods.softCap(),
          poolContract.methods.liquidityLockDays(),
          poolContract.methods.liquidityPercent(),
          poolContract.methods.liquidityUnlockTime(),
          poolContract.methods.poolDetails(),
          poolContract.methods.poolState(),
          poolContract.methods.getPrice(),
          poolContract.methods.token(),
          poolContract.methods.totalClaimed(),
          poolContract.methods.totalRaised(),
          poolContract.methods.refundType(),
          poolContract.methods.governance(),
          poolContract.methods.kyc(),
          poolContract.methods.audit(),
          poolContract.methods.auditStatus(),
          poolContract.methods.kycStatus(),
          pmc.methods.poolPartnerList(urlAddress),
          poolContract.methods.totalToken(),
        ]);



        let tokenContract = new web3.eth.Contract(tokenAbi, data[9]);

        const tokendata = await mc.aggregate([
          tokenContract.methods.name(),
          tokenContract.methods.decimals(),
          tokenContract.methods.symbol(),
          tokenContract.methods.totalSupply(),
          tokenContract.methods.balanceOf('0x000000000000000000000000000000000000dead'),
        ]);

        const lockdata = await mc.aggregate([
          lmc.methods.cumulativeLockInfo(data[9]),
          pmc.methods.getInsurancePrice()
        ]);

        let partnerData = await axios.get(`${Api_Baseurl}api/admin/get-kyc-link`, {
          params: {
            poolAddress: urlAddress
          }
        });


        setStats({
          endTime: data[0],
          startTime: data[1],
          softCap: data[2] / Math.pow(10, 18),
          liquidityLockDays: data[3],
          liquidityPercent: data[4],
          liquidityUnlockTime: data[5],
          poolDetails: data[6],
          poolState: data[7],
          rate: data[8] / Math.pow(10, tokendata[1]),
          token: data[9],
          totalClaimed: data[10],
          totalRaised: data[11] / Math.pow(10, 18),
          tokenName: tokendata[0],
          tokenDecimal: tokendata[1],
          tokenSymbol: tokendata[2],
          percentageRaise: ((data[11] / Math.pow(10, 18)) / (data[2] / Math.pow(10, 18))) * 100,
          tokenSupply: tokendata[3] / Math.pow(10, tokendata[1]),
          refundType: data[12],
          poolAddress: urlAddress,
          governance: data[13],
          kyc: data[14],
          audit: data[15],
          auditStatus: data[16],
          kycStatus: data[17],
          partnerList: data[18],
          totalPresaleToken: parseFloat(data[19] / Math.pow(10, tokendata[1])),
          currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol,
          PresalePer: ((data[19]) / (tokendata[3])) * 100,
          LiquidityPer: (((data[19] * data[4]) / 100 ) / (tokendata[3])) * 100,
          BurntPer: ((tokendata[4]) / (tokendata[3])) * 100,
          LockPer: lockdata[0] ?  ((lockdata[0][2]) / (tokendata[3])) * 100 : 0,
          insurcePrice : lockdata[1] / Math.pow(10,18),
          kycLink : typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK' ? partnerData.data.data.kycLink : '#sec',
          auditLink : typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK' ? partnerData.data.data.auditLink : '#sec',
        });
      }
      catch (err) {
        toast.error('wrong network selected !');
        console.log(err.message);
        history.push('/sale-list');
      }
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        endTime: 0,
        startTime: 0,
        softCap: 0,
        liquidityLockDays: 0,
        liquidityPercent: 0,
        liquidityUnlockTime: 0,
        poolDetails: 0,
        poolState: 0,
        rate: 0,
        token: 0,
        totalClaimed: 0,
        totalRaised: 0,
        tokenName: '',
        tokenDecimal: 0,
        tokenSymbol: 0,
        tokenSupply: 0,
        percentageRaise: 0,
        refundType: 0,
        poolAddress: 0,
        governance: 0,
        kyc: false,
        audit: false,
        auditStatus: false,
        kycStatus: false,
        partnerList: [],
        totalPresaleToken: 0,
        insurcePrice : 0,
        kycLink: '#',
        auditLink: '#',
        currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol
      })
    }
    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
}

export const useAccountStats = (updater) => {
  const context = useWeb3React();
  const { chainId, account } = context;
  const location = useLocation();
  let history = useHistory();
  let urlAddress = location.pathname.split('/').pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get('chainid');

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);

  try {
    let poolAddress = web3.utils.isAddress(urlAddress);
    let isCode = web3.eth.getCode(urlAddress);
    if (isCode === '0x' || !poolAddress) {
      // history.push('/sale-list');
    }
  }
  catch (err) {
    console.log(err.message);
    history.push('/');
  }



  let poolContract = new web3.eth.Contract(presalePoolAbi, urlAddress);

  const [stats, setStats] = useState({
    balance: '',
    contributionOf: '',
    userAvalibleClaim: '',
    haveInsurance : false
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
  const mcc = multiCallContractConnect(queryChainId ? queryChainId : chainId);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          mcc.methods.getEthBalance(account),
          poolContract.methods.contributionOf(account),
          poolContract.methods.userAvalibleClaim(account),
          poolContract.methods.isInsurance(account)

        ]);

        setStats({
          balance: data[0] / Math.pow(10, 18),
          contributionOf: data[1] / Math.pow(10, 18),
          userAvalibleClaim: data[2] / Math.pow(10, 18),
          haveInsurance :  data[3]
        })
      }
      catch (err) {
        toast.error(err.message)
        // history.push('/sale-list');
      }
    }

    if (mc && account) {
      fetch();
    }
    else {
      setStats({
        balance: '',
        contributionOf: '',
        userAvalibleClaim: '',
        haveInsurance : false
      })
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
}


