import React, { useState, useContext, useEffect } from 'react';
import './index.css';
import Lock from './Lock';
import Context from "./context/Context";
import { useWeb3React } from "@web3-react/core";
import { defaultValue } from './context/defaults';



export default function MainLock() {

    const appContext = useContext(Context);
    const [context, setContext] = useState(appContext);
    const { chainId, account } = useWeb3React();

    const setValue = value => {
        console.log({ ...context, value });
        setContext({ ...context, value });
    };

    useEffect(() => {
        setContext({ ...context, value: { ...defaultValue } });
        // eslint-disable-next-line
    }, [chainId, account])





    const state = {
        ...context,
        setValue: setValue
    };







    return (
        <Context.Provider value={state}>
            <React.Fragment>
                <div className="gamfi-breadcrumbs-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="breadcrumbs-area sec-heading">
                                    <div className="sub-inner mb-15">
                                        <span className="sub-title blue-color">Lock Token & Liquidity</span>
                                        <img className="heading-left-image" src="assets/images/icons/steps.png" alt="StepsImage" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="signup-step-container">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-10">

                                <div className="card stepcard">
                                    <div className="card-body">
                                        <form className="login-box">
                                            <Lock />

                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        </Context.Provider>
    )
}
