import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supportNetwork } from '../../../../hooks/network';
import Countdown, { zeroPad } from "react-countdown";
import HashLoader from "react-spinners/HashLoader";
import { formatPrice } from '../../../../hooks/contractHelper';
import { usePoolListStats } from '../helper/useStats';
import { Api_Baseurl } from '../../../../hooks/constant';
import axios from 'axios';
import { toast } from 'react-toastify';



export default function Opensale(props) {
    let { chainSelect } = props;
    const [updater, setUpdater] = useState({ page: 0, pageSize: 7, loading: true });
    const stats = usePoolListStats(updater);
    const [currentTime] = useState(Math.floor(new Date().getTime() / 1000.0));

    const [page, setPage] = useState(0);
    const [pageSize] = useState(7);
    const [openSale, setOpenSale] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        async function fetchData() {
            let url = '';
            if (chainSelect.saleStatus === 'All' || chainSelect.saleStatusId === null) {
                url = `${Api_Baseurl}api/pooldata/getPools`;
            }
            else if (chainSelect.saleStatusId === '1') {
                url = `${Api_Baseurl}api/pooldata/getUpcomingPools`;
            }
            else if (chainSelect.saleStatusId === '2') {
                url = `${Api_Baseurl}api/pooldata/getActivePools`;
            }
            else if (chainSelect.saleStatusId === '3') {
                url = `${Api_Baseurl}api/pooldata/getPastPools`;
            }
            else {
                url = `${Api_Baseurl}api/pooldata/getPools`;
            }

            axios.get(url, {
                params: {
                    page: page,
                    pageSize: pageSize,
                    chainId: chainSelect.chainId,
                    tokenName: chainSelect.search,
                    ispresale: chainSelect.ispresale
                }
            })
                .then(function (response) {
                    if (response) {
                        let data = response.data;
                        if (data.error === 'OK') {
                            setOpenSale(data.data);
                            setCount(data.count);
                        }
                        else {
                            toast.error('Something went wrong ! try again')

                        }
                    }
                })
                .catch(function (error) {
                    toast.error('Something went wrong ! try again')
                })
        }

        fetchData();
        // eslint-disable-next-line
    }, [props]);

    const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className="timer timer_1">
                    <ul>
                        <li><span className="counter">{zeroPad(days, 2)}</span>D</li>
                        <li><span className="counter">{zeroPad(hours, 2)}</span>H</li>
                        <li><span className="counter">{zeroPad(minutes, 2)}</span>M</li>
                        <li><span className="counter">{zeroPad(seconds, 2)}</span>S</li>
                    </ul>
                </div>
            );
        } else {
            // Render a countdown
            return (
                <div className="timer timer_1">
                    <ul>
                        <li><span className="counter">{days}</span>D</li>
                        <li><span className="counter">{hours}</span>H</li>
                        <li><span className="counter">{minutes}</span>M</li>
                        <li><span className="counter">{seconds}</span>S</li>
                    </ul>
                </div>
            );
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        let url = '';
        if (chainSelect.saleStatus === 'All' || chainSelect.saleStatusId === null) {
            url = `${Api_Baseurl}api/pooldata/getPools`;
        }
        else if (chainSelect.saleStatusId === '1') {
            url = `${Api_Baseurl}api/pooldata/getUpcomingPools`;
        }
        else if (chainSelect.saleStatusId === '2') {
            url = `${Api_Baseurl}api/pooldata/getActivePools`;
        }
        else if (chainSelect.saleStatusId === '3') {
            url = `${Api_Baseurl}api/pooldata/getPastPools`;
        }
        else {
            url = `${Api_Baseurl}api/pooldata/getPools`;
        }

        axios.get(url, {
            params: {
                page: parseInt(page) + 1,
                pageSize: pageSize,
                chainId: chainSelect.chainId,
                tokenName: chainSelect.search,
                ispresale: chainSelect.ispresale
            }
        })
            .then(function (response) {
                if (response) {
                    let data = response.data;
                    if (data.error === 'OK') {
                        setOpenSale(data.data);
                        setPage(parseInt(page) + 1);
                        setCount(data.count);
                        window.scrollTo(0, 0);
                    }
                    else {
                        toast.error('Something went wrong ! try again')

                    }
                }
            })
            .catch(function (error) {
                toast.error('Something went wrong ! try again')
            })

    }

    const handlePreviousPage = (e) => {
        e.preventDefault();

        let url = '';
        if (chainSelect.saleStatus === 'All' || chainSelect.saleStatusId === null) {
            url = `${Api_Baseurl}api/pooldata/getPools`;
        }
        else if (chainSelect.saleStatusId === '1') {
            url = `${Api_Baseurl}api/pooldata/getUpcomingPools`;
        }
        else if (chainSelect.saleStatusId === '2') {
            url = `${Api_Baseurl}api/pooldata/getActivePools`;
        }
        else if (chainSelect.saleStatusId === '3') {
            url = `${Api_Baseurl}api/pooldata/getPastPools`;
        }
        else {
            url = `${Api_Baseurl}api/pooldata/getPools`;
        }


        axios.get(url, {
            params: {
                page: parseInt(page) - 1,
                pageSize: pageSize,
                chainId: chainSelect.chainId,
                tokenName: chainSelect.search,
                ispresale: chainSelect.ispresale
            }
        })
            .then(function (response) {
                if (response) {
                    let data = response.data;
                    if (data.error === 'OK') {
                        setOpenSale(data.data);
                        setPage(parseInt(page) - 1);
                        setCount(data.count);
                        window.scrollTo(0, 0);
                    }
                    else {
                        toast.error('Something went wrong ! try again')

                    }
                }
            })
            .catch(function (error) {
                toast.error('Something went wrong ! try again')
            })

    }

    return (
        <div className="tab-pane fade show active" id="open-igo" role="tabpanel" aria-labelledby="home-tab">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="previous-mainmenu mb-15">
                        <ul className="menu-list">
                            <li className="list1">Project</li>
                            <li className="list2">Chain</li>
                            <li className="list3">Start/End</li>
                            <li className="list4">Total Raise</li>
                            <li className="list5">Progress</li>
                            <li className="list6">Type</li>
                        </ul>
                    </div>
                </div>
                {stats.loading ? (
                    <div className='col-md-12'>
                        <HashLoader size="100" color="#fff" cssOverride={{ "left": "50%", "textAlign": "center", "top": "50%" }} />
                    </div>
                ) : (
                    <React.Fragment>
                        {(chainSelect.chainId === null || chainSelect.chainId === 'null'  || chainSelect.chainId === 56 || chainSelect.chainId === '56') &&
                            <div className="col-md-12">
                                <Link to={`/ember-presale/?chainid=56`}>
                                    <div className="previous-item hover-shape-border hover-shape-inner">
                                        <div className="previous-gaming">
                                            <div className="previous-image">
                                                <img src="assets\images\default_images\ember.jpeg" alt="PreviousImage12" height="80px" width="80px" />
                                            </div>
                                            <div className="previous-price">
                                                <h4 className="mb-10">Ember Token</h4>
                                                <div className="dsc">1 BNB ~ 14,477,367.049 EMBER</div>
                                            </div>
                                        </div>
                                        <div className="previous-chaining">
                                            <img src={`../assets/images/${supportNetwork[56].image}`} alt="ChainImage12" width="40px" height="40px" />
                                            <div className="counter-info">
                                                <div className='text-center'>
                                                    End In
                                                </div>

                                                <Countdown
                                                    key={Math.floor(Math.random() * 10 + 1)}
                                                    date={1663430400000}
                                                    renderer={countdownrender}
                                                />

                                            </div>
                                        </div>
                                        <div className="previous-raise">
                                            <span>398.5 BNB ( 100% )</span>
                                            <div className="progress-inner">
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow={100} aria-valuemin="0" aria-valuemax="100" style={{ "width": "100%" }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 class="tag-btn text-uppercase text-center bg-green">Presale</h4>
                                        </div>
                                        <span className="border-shadow shadow-1"></span>
                                        <span className="border-shadow shadow-2"></span>
                                        <span className="border-shadow shadow-3"></span>
                                        <span className="border-shadow shadow-4"></span>
                                        <span className="hover-shape-bg hover_shape1"></span>
                                        <span className="hover-shape-bg hover_shape2"></span>
                                        <span className="hover-shape-bg hover_shape3"></span>
                                    </div>
                                </Link>
                            </div>
                        }
                        {
                            // !chainSelect.isFilter ? (
                            //     stats.poolList.length > 0 ? (
                            //         stats.poolList.slice(0).reverse().map((rowdata, index) => {
                            //             let chainSymbol = supportNetwork[stats.chainId] ? supportNetwork[stats.chainId].image : supportNetwork['default'].image
                            //             let currencySymbol = supportNetwork[stats.chainId] ? supportNetwork[stats.chainId].symbol : supportNetwork['default'].symbol
                            //             return (
                            //                 <div className="col-md-12" key={index}>
                            //                     <Link to={rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '1' ? `/privatesale-details/${rowdata.poolAddress}${stats.chainId ? `?chainid=${stats.chainId}` : ''}` : rowdata.poolType === '2' ? `/fairlaunch-details/${rowdata.poolAddress}?chainid=${stats.chainId}` : '/'}>
                            //                         <div className="previous-item hover-shape-border hover-shape-inner">
                            //                             <div className="previous-gaming">
                            //                                 <div className="previous-image">
                            //                                     <img src={rowdata.logourl} alt="PreviousImage12" height="80px" width="80px" />
                            //                                 </div>
                            //                                 <div className="previous-price">
                            //                                     <h4 className="mb-10">{rowdata.name}</h4>
                            //                                     <div className="dsc">1 {currencySymbol} ~ {rowdata.poolType === '2' ? 'N/A' : formatPrice(rowdata.rate)} {rowdata.symbol}</div>
                            //                                 </div>
                            //                             </div>
                            //                             <div className="previous-chaining">
                            //                                 <img src={`../assets/images/${chainSymbol}`} alt="ChainImage12" width="40px" height="40px" />
                            //                                 <div className="counter-info">
                            //                                     <div className='text-center'>
                            //                                         {rowdata.poolState === '1' ? 'Completed' :
                            //                                             rowdata.poolState === '2' ? 'Cancelled' :
                            //                                                 rowdata.poolState === '0' ?
                            //                                                     rowdata.startTime > currentTime ? 'Start In' : 'End In' : 'Ended'
                            //                                         }
                            //                                     </div>
                            //                                     {rowdata.poolState === '0' &&
                            //                                         <Countdown
                            //                                             key={Math.floor(Math.random() * 10 + 1)}
                            //                                             date={rowdata.startTime > currentTime ? rowdata.startTime * 1000 : rowdata.endTime * 1000}
                            //                                             renderer={countdownrender}
                            //                                         />
                            //                                     }
                            //                                 </div>
                            //                             </div>
                            //                             <div className="previous-raise">
                            //                                 <span>{parseFloat(rowdata.totalRaised).toFixed(3)} {currencySymbol}  ({parseFloat(rowdata.percentageRaise).toFixed(2)}%)</span>
                            //                                 <div className="progress-inner">
                            //                                     <div className="progress">
                            //                                         <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow={parseFloat(rowdata.percentageRaise).toFixed(2)} aria-valuemin="0" aria-valuemax="100" style={{ "width": `${parseFloat(rowdata.percentageRaise).toFixed(2)}%` }}>
                            //                                         </div>
                            //                                     </div>
                            //                                 </div>
                            //                             </div>
                            //                             <div>
                            //                                 {rowdata.poolType === '0' ?
                            //                                     <h4 class="tag-btn text-uppercase text-center bg-green">Presale</h4> :
                            //                                     // <span className='badge badge-success'>Presale</span> : 
                            //                                     rowdata.poolType === '1' ? <h4 class="tag-btn text-uppercase text-center bg-pink">Privatesale</h4> :
                            //                                         rowdata.poolType === '2' ? <h4 class="tag-btn text-uppercase text-center bg-blue">FairLaunch</h4> : ' ~ '}
                            //                             </div>
                            //                             <span className="border-shadow shadow-1"></span>
                            //                             <span className="border-shadow shadow-2"></span>
                            //                             <span className="border-shadow shadow-3"></span>
                            //                             <span className="border-shadow shadow-4"></span>
                            //                             <span className="hover-shape-bg hover_shape1"></span>
                            //                             <span className="hover-shape-bg hover_shape2"></span>
                            //                             <span className="hover-shape-bg hover_shape3"></span>
                            //                         </div>
                            //                     </Link>
                            //                 </div>
                            //             )
                            //         })
                            //     ) : (
                            //         <div className='d-flex justify-content-center'></div>
                            //     )
                            // ) :
                            // (
                            openSale.map((rowdata, index) => {
                                let chainSymbol = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].image : supportNetwork['default'].image
                                let currencySymbol = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].symbol : supportNetwork['default'].symbol
                                return (
                                    <div className="col-md-12" key={index}>
                                        <Link to={rowdata.ispresale === '0' ? `/presale-details/${rowdata.poolAddress}?chainid=${rowdata.chainId}` : rowdata.ispresale === '1' ? `/privatesale-details/${rowdata.poolAddress}?chainid=${rowdata.chainId}` : rowdata.ispresale === '2' ? `/fairlaunch-details/${rowdata.poolAddress}?chainid=${rowdata.chainId}` : '/'}>
                                            <div className="previous-item hover-shape-border hover-shape-inner">
                                                <div className="previous-gaming">
                                                    <div className="previous-image">
                                                        <img src={rowdata.logourl} alt="PreviousImage12" height="80px" width="80px" />
                                                    </div>
                                                    <div className="previous-price">
                                                        <h4 className="mb-10">{rowdata.tokenName}</h4>
                                                        <div className="dsc">1 {currencySymbol} ~ {formatPrice(rowdata.rate)} {rowdata.tokenSymbol}</div>
                                                    </div>
                                                </div>
                                                <div className="previous-chaining">
                                                    <img src={`../assets/images/${chainSymbol}`} alt="ChainImage12" width="40px" height="40px" />
                                                    <div className="counter-info">
                                                        <div className='text-center'>
                                                            {rowdata.poolState === '1' ? 'Completed' :
                                                                rowdata.poolState === '2' ? 'Cancelled' :
                                                                    rowdata.poolState === '0' ?
                                                                        rowdata.startTime > currentTime ? 'Start In' : 'End In' : 'Ended'
                                                            }
                                                        </div>
                                                        {rowdata.poolState === '0' &&
                                                            <Countdown
                                                                key={Math.floor(Math.random() * 10 + 1)}
                                                                date={rowdata.startTime > currentTime ? rowdata.startTime * 1000 : rowdata.endTime * 1000}
                                                                renderer={countdownrender}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="previous-raise">
                                                    <span>{rowdata.totalRaised} {currencySymbol}  ({parseFloat(rowdata.percentageRaise).toFixed(2)}%)</span>
                                                    <div className="progress-inner">
                                                        <div className="progress">
                                                            <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow={parseFloat(rowdata.percentageRaise).toFixed(2)} aria-valuemin="0" aria-valuemax="100" style={{ "width": `${parseFloat(rowdata.percentageRaise).toFixed(2)}%` }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {rowdata.ispresale === '0' ?
                                                        <h4 class="tag-btn text-uppercase text-center bg-green">Presale</h4> :
                                                        // <span className='badge badge-success'>Presale</span> : 
                                                        rowdata.ispresale === '1' ? <h4 class="tag-btn text-uppercase text-center bg-pink">Privatesale</h4> :
                                                            rowdata.ispresale === '2' ? <h4 class="tag-btn text-uppercase text-center bg-blue">FairLaunch</h4> : ' ~ '}
                                                </div>
                                                <span className="border-shadow shadow-1"></span>
                                                <span className="border-shadow shadow-2"></span>
                                                <span className="border-shadow shadow-3"></span>
                                                <span className="border-shadow shadow-4"></span>
                                                <span className="hover-shape-bg hover_shape1"></span>
                                                <span className="hover-shape-bg hover_shape2"></span>
                                                <span className="hover-shape-bg hover_shape3"></span>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                            // )
                        }
                    </React.Fragment>
                )}

            </div>
            {!chainSelect.isFilter ? (
                <div className="gamfi-navigation text-center mt-10">
                    <ul>
                        <li>
                            {stats.page > 0 &&
                                <a href="#sec" onClick={(e) => setUpdater({ page: stats.page - 1, pageSize: stats.pageSize, loading: true })}>
                                    <i className="icon-Vector mr-2"></i>Previous
                                </a>
                            }
                        </li>
                        {Math.floor(stats.getTotalNumberOfPools / stats.pageSize) > stats.page &&
                            < li >
                                <a href="#sec" onClick={(e) => setUpdater({ page: stats.page + 1, pageSize: stats.pageSize, loading: true })}>
                                    Next<i className="icon-arrow_right ml-2"></i>
                                </a>
                            </li>
                        }
                    </ul>
                </div>
            ) : (
                <div className="gamfi-navigation text-center mt-10">
                    <ul>
                        <li>
                            {page > 0 &&
                                <a href="#sec" onClick={(e) => handlePreviousPage(e)}>
                                    <i className="icon-Vector mr-2"></i>Previous
                                </a>
                            }
                        </li>
                        {Math.floor(count - 1 / pageSize) > page &&
                            < li >
                                <a href="#sec" onClick={(e) => handleNextPage(e)}>
                                    Next<i className="icon-arrow_right ml-2"></i>
                                </a>
                            </li>
                        }
                    </ul>
                </div>
            )
            }
        </div >
    )
}
