import React, { useState } from 'react';
import { Link } from "react-router-dom"
import Connect from './Connect';
import MobileConnect from './MobileConnect';

export default function Header() {

    const [open, setOpen] = useState(false);

    const toggleNav = (e) => {
        setOpen((prev) => !prev);
    }

    return (
        <React.Fragment>

            {/* <section className="loader_first">
                <div className="circular-spinner"></div>
            </section> */}
            {/* Preloader area End here */}

            {/* Header Start */}
            <header id="gamfi-header" className={`gamfi-header-section transparent-header ${open ? 'nav-expanded' : ''}`}>
                <div className="menu-area menu-sticky">
                    <div className="container">
                        <div className="heaader-inner-area d-flex justify-content-between align-items-center">
                            <div className="gamfi-logo-area d-flex justify-content-between align-items-center">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="../assets/images/default_images/Untitled11.png" alt="logo" style={{ "height": "50px" }} />
                                    </Link>
                                </div>
                                <div className="header-menu">
                                    <ul className="nav-menu">
                                        <li><Link to="/">Home</Link></li>
                                        {/* <li>
                                            <a href="#sec">Token Stake</a>
                                            <ul className="sub-menu">
                                                <li><a href="https://staking.blockstar.site/" rel="noreferrer" target="_blank">Stake V1</a></li>
                                                <li><a href="https://staking.blockstar.site/" rel="noreferrer" target="_blank">Stake V2(Live)</a></li>
                                            </ul>
                                        </li> */}
                                        {/* <li><a href="https://staking.blockstar.site/" rel="noreferrer" target="_blank" >NFT Stake</a></li> */}
                                        {/* <li><Link to="/farming">LP Stake</Link></li> */}
                                        <li>
                                            <a href="#sec">Launchpad</a>
                                            <ul className="sub-menu">
                                                <li><Link to="/presale">Create Presale</Link></li>
                                                <li><Link to="/privatesale">Create Private Sale</Link></li>
                                                <li><Link to="/fairsale">Create Fair Launch</Link></li>
                                                <li><Link to="/sale-list">Launchpad List</Link></li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#sec">Lock</a>
                                            <ul className="sub-menu">
                                                <li><Link to="/lock">Lock Token</Link></li>
                                                <li><Link to="/lock">Lock LP</Link></li>
                                                <li><Link to="/lock-list">Locker List</Link></li>
                                                <li><Link to="/my-token-lock">My Token Lock</Link></li>
                                                <li><Link to="/my-lp-lock">My LP Lock</Link></li>
                                            </ul>
                                        </li>
                                        <li><a href="https://launchpad.blockstar.site/swap/" rel="noreferrer" target="_blank" >Swap</a></li>
                                        <li>
                                            <a href="https://blockstar.site/portfolio" target="_blank" rel="noreferrer">Portfolio</a>
                                        </li>
                                        {/* <li><Link to="/liquidity">Liquidity</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="gamfi-btn-area">
                                <ul>
                                    <li>
                                        <a href="#sec" id="nav-expander" onClick={(e) => { toggleNav(e) }} className="nav-expander bar">
                                            <div className="bar">
                                                <span className="dot1"></span>
                                                <span className="dot2"></span>
                                                <span className="dot3"></span>
                                            </div>
                                        </a>
                                    </li>
                                    {/* <li className="buy-token">
                                        <a className="readon black-shape" href="#sec">
                                            <span className="btn-text">Buy Token </span>
                                            <i className="icon-arrow_down"></i>
                                            <span className="hover-shape1"></span>
                                            <span className="hover-shape2"></span>
                                            <span className="hover-shape3"></span>
                                        </a>
                                        <ul>
                                            <li>
                                                <a target="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap?outputCurrency=0x02137Da913f50dedf6F06D5a7668670bb0f023B9">
                                                    <img src="../assets/images/icons/pancake.png" alt="pancake" /> PancakeSwap
                                                </a>
                                            </li>
                                        </ul>
                                    </li> */}
                                    <Connect />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Canvas Mobile Menu start  */}
                <nav className="right_menu_togle mobile-navbar-menu" id="mobile-navbar-menu">
                    <div className="close-btn">
                        <a href='#sec' onClick={toggleNav} className="nav-close">
                            <div className="line">
                                <span className="line1"></span>
                                <span className="line2"></span>
                            </div>
                        </a>
                    </div>
                    <div className="sidebar-logo mb-30">
                        <Link to="/">
                            <img src="../assets/images/default_images/Untitled11.png" alt="logo-dark" style={{ "height": "60px" }} />
                        </Link>
                    </div>

                    <ul className="nav-menu">
                        <li><Link to="/">Home</Link></li>
                        {/* <li><a href="#sec">Token Stake</a>
                            <ul className="sub-menu">
                                <li><a href="https://staking.blockstar.site/" rel="noreferrer" target="_blank">Stake V1</a></li>
                                <li><a href="https://staking.blockstar.site/" rel="noreferrer" target="_blank">Stake V2(Live)</a></li>
                            </ul>
                        </li>
                        <li><a href="https://staking.blockstar.site/" rel="noreferrer" target="_blank">NFT Stake</a></li> */}
                        {/* <li><a href="#sec">Buy Token</a>
                            <ul className="sub-menu">
                                <li>
                                    <a target="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap?outputCurrency=0x02137Da913f50dedf6F06D5a7668670bb0f023B9">
                                        <img src="../assets/images/pancake_favicon.ico" alt="pancake" /> PancakeSwap
                                    </a>
                                </li>

                            </ul>
                        </li> */}
                        <li class="has-sub">
                            <span class="submenu-button"><em></em></span><a href="#sec">Launchpads</a>
                            <ul class="sub-menu" style={{ "display": "none" }}>
                                <li><Link to="/presale">Create Presale</Link></li>
                                <li><Link to="/privatesale">Create Private Sale</Link></li>
                                <li><Link to="/fairsale">Create Fair Launch</Link></li>
                                <li><Link to="/sale-list">Launchpad List</Link></li>
                            </ul>
                        </li>
                        <li class="has-sub">
                            <span class="submenu-button"><em></em></span><a href="#sec">Lock</a>
                            <ul class="sub-menu" style={{ "display": "none" }}>
                                <li><Link to="/lock">Lock Token</Link></li>
                                <li><Link to="/lock">Lock LP</Link></li>
                                <li><Link to="/lock-list">Locker List</Link></li>
                                <li><Link to="/my-token-lock">My Token Lock</Link></li>
                                <li><Link to="/my-lp-lock">My LP Lock</Link></li>
                            </ul>
                        </li>
                        <li><a href="https://launchpad.blockstar.site/swap/" rel="noreferrer" target="_blank">Swap</a></li>
                        <li>
                            <a href="https://blockstar.site/portfolio" target="_blank" rel="noreferrer">Portfolio</a>
                        </li>
                        {/* <li><Link to="/liquidity">Liquidity</Link></li> */}
                        <MobileConnect />
                    </ul>
                </nav>
            </header>
        </React.Fragment>
    )
}
